<template>
  <div class="">
    <div
      v-if="permissions['prices.index'] == false"
      style="height: 50vh"
      class="d-flex align-center justify-center"
    >
      <h1 class="display-2 text-center">
        You are not allowed to view this page
      </h1>
    </div>
    <div v-else class="home">
      <v-container fluid class="bg-white mt-65">
        <div class="d-flex">
          <h1 class="primetime">Prices</h1>

          <v-col class="ml-auto" cols="12" md="2">
            <manage
              @dataUpdated="getData()"
              :id="editId"
              :show="formShow"
              @cancelForm="formShow = false"
            />
          </v-col>
        </div>
        <v-row>
          <v-col cols="12" md="12" class="text-right mt-4">
            <h5>
              Showing : {{ this.showingFrom ? this.showingFrom : "0" }} -
              {{ this.showingTo ? this.showingTo : "0" }} of
              {{ this.count ? this.count : "0" }}
            </h5>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="10" class="pb-0 pt-0">
            <v-container>
              <v-row> </v-row>
            </v-container>
          </v-col>

          <v-col cols="12" md="2"> </v-col>
        </v-row>
        <confirmation
          :confirming="deletingId"
          @confirmed="remove"
          :show="deleteConfirmationShow"
          @canceled="deleteConfirmationShow = false"
        />

        <v-simple-table class="mt-10" v-if="prices.data && !loading">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">#</th>
                <th class="text-left">Month</th>
                <th class="text-left">Pricing type</th>
                <th class="text-left">Status</th>
                <th class="text-left">Created At</th>
                <th class="text-left">Updated At</th>
                <th class="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>

                <td class="py-3">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="Month"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @keypress.enter="manualSearch"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      type="month"
                      @input="menu = false"
                      no-title
                      scrollable
                    ></v-date-picker>
                  </v-menu>
                </td>

                <td>
                  <v-select
                    item-text="title"
                    item-value="id"
                    :items="priceTypes"
                    v-model="filters.pricing_type"
                    @change="manualSearch"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </td>
                <td>
                  <v-select
                    item-text="title"
                    item-value="id"
                    :items="statuses"
                    v-model="filters.status"
                    @change="manualSearch"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </td>
                <td>
                  <v-text-field
                    @keypress.enter="manualSearch"
                    v-model="filters.created_at"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    @keypress.enter="manualSearch"
                    v-model="filters.updated_at"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </td>
                <td>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    :loading="filtering"
                    @click="manualSearch"
                  >
                    <v-icon dark>mdi-clipboard-text-search</v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    :loading="filtering"
                    @click="clearFilter"
                  >
                    <v-icon dark>mdi-close-octagon</v-icon>
                  </v-btn>
                </td>
              </tr>

              <tr v-for="(item, i) in prices.data" :key="item.name">
                <td>{{ i + 1 + (pagination.current - 1) * 20 }}</td>
                <td>{{ item.month_name }}</td>
                <td>{{ item.pricing_type_name }}</td>
                <td>
                  <v-chip
                    class="ma-2"
                    small
                    v-if="item.status_name === 'Active'"
                    color="success"
                    >{{ item.status_name }}</v-chip
                  >
                  <v-chip class="ma-2" small color="warning" v-else>{{
                    item.status_name
                  }}</v-chip>
                </td>
                <td>{{ item.created_at }}</td>
                <td>{{ item.updated_at }}</td>

                <td>
                  <div v-if="permissions['prices.view'] == false"></div>
                  <v-btn v-else text :to="`/prices/${item.id}`">
                    <v-icon>mdi-eye-outline</v-icon>
                  </v-btn>
                  <div v-if="permissions['prices.update'] == false"></div>
                  <v-btn v-else text @click="edit(item.id)" color="blue">
                    <v-icon>mdi-file-edit</v-icon>
                  </v-btn>
                  <div v-if="permissions['prices.destroy'] == false"></div>
                  <v-btn v-else text @click="destroy(item.id)" color="red">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!--<div class="text-xs-center" v-else>-->
        <!--<v-btn primary>-->
        <!--<rotate-square5></rotate-square5>-->
        <!--</v-btn>-->
        <!--</div>-->

        <vue-content-loading :width="300" :height="100" v-if="loading">
          <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
        </vue-content-loading>

        <v-row justify="center" style="margin: 0">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination
                v-model="pagination.current"
                :length="pagination.total"
                class="my-4"
                :total-visible="7"
                circle
                @input="getData(pagination.current, filtersUrl())"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Confirmation from "@/components/Confirmation.vue";
import Api from "@/services/Api";
import Manage from "@/views/prices/Manage.vue";

import { VueContentLoading } from "vue-content-loading";

export default {
  name: "Prices",
  components: {
    Confirmation,
    Manage,
    VueContentLoading,
  },
  data() {
    return {
      permissions: JSON.parse(localStorage.getItem("permissions")),
      date: "",
      menu: false,
      modal: false,
      editId: null,
      deletingId: null,
      formShow: false,
      deleteConfirmationShow: false,
      prices: {},
      pagination: {
        current: 1,
        total: 0,
      },
      filters: {},
      pricing_type: {},
      loading: false,
      filtering: false,
      statuses: [
        { id: "", title: "Select Status" },
        { id: 1, title: "Active" },
        { id: 2, title: "In-Active" },
      ],
      priceTypes: [
        { title: "Select status", id: "" },
        { title: "Towing Price", id: 1 },
        { title: "Ocean Freight Price", id: 2 },
        { title: "Bike towing", id: 3 },
        { title: "Single Vehicle Rate", id: 4 },
        { title: "Destination Rate", id: 5 },
        { title: "Full Container Rate", id: 6 },
        { title: "Half Cut Towing Rate", id: 7 },
        { title: "Half Cut Shipping Rate", id: 8 },
      ],
      showingFrom: 0,
      showingTo: 0,
      count: 0,
    };
  },
  methods: {
    selectedItem() {
      let value = {};
      value = this.priceTypes.find(
        (item) => item.id == this.$route.query.pricing_type
      );
      this.pricing_type = value;

      return value;
    },
    getData(page, searchQuery = "") {
      this.loading = true;
      if (!page) {
        page = this.pagination.current;
      }
      if (searchQuery) {
        searchQuery = `&${searchQuery}`;
      }

      this.filters.page = page;
      let totalSortFilterQuery = this.filtersUrl();

      let finalUrl = this.$route.path + `?` + totalSortFilterQuery;
      window.history.pushState({}, "", finalUrl);

      Api.get(`/pricing?${totalSortFilterQuery}`)
        .then((res) => {
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
          this.prices = res.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },

    getDataWithFilter(searchQuery) {
      Api.get(`/pricing?${searchQuery}`)
        .then((res) => {
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
          this.prices = res.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },
    filtersUrl() {
      var str = "";
      for (var key in this.filters) {
        if (str != "") {
          str += "&";
        }
        str += key + "=" + encodeURIComponent(this.filters[key]);
      }
      return str;
    },
    edit(id) {
      this.editId = id;
      this.formShow = true;
    },
    destroy(id) {
      this.deletingId = id;
      this.deleteConfirmationShow = true;
    },
    remove() {
      Api.delete(`/pricing/${this.deletingId}`)
        .then(() => {
          this.getData();
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });

      this.editId = null;
      this.deletingId = null;
      this.formShow = false;
    },
    // search() {
    //   if (this.date) {
    //     this.filters.month = this.customFormatter(this.date);
    //   }
    //   // this.filters.pricing_type = this.pricing_type;

    //   this.getData(1, this.filtersUrl());
    // },
    search() {
      let page = this.filters.page ? this.filters.page : 1;
      if (this.date) {
        this.filters.month = this.customFormatter(this.date);
      }
      this.getData(page, this.filtersUrl());
    },
    manualSearch() {
      this.filters.page = 1;
      if (this.date) {
        this.filters.month = this.customFormatter(this.date);
      }
      this.search();
    },

    customFormatter(date) {
      let dateObj = new Date(date);
      return (
        dateObj.toLocaleString("en-US", { month: "short" }) +
        "-" +
        dateObj.year()
      );
    },

    clearFilter() {
      this.filters = {};
      this.getData();
      this.date = "";
    },
  },
  mounted() {
    this.selectedItem();

    if (Object.keys(this.$route.query).length) {
      this.filters = this.$route.query;

      if (this.filters.status) {
        this.filters.status = parseInt(this.filters.status);
      }
      if (this.filters.pricing_type) {
        this.filters.pricing_type = parseInt(this.filters.pricing_type);
      }

      this.getDataWithFilter(this.filtersUrl());
    } else {
      this.getData();
    }
  },

  watch: {
    // "filters.status"() {
    //   this.search();
    // },
    // "filters.pricing_type"() {
    //   this.search();
    // },
  },
};
</script>
<style scoped>
.home {
  background-color: #f8f8fb;
}
</style>

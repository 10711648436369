<template>
  <div>
<!--    <div-->
<!--      v-if="permissions['countries.index'] == false"-->
<!--      style="height: 50vh"-->
<!--      class="d-flex align-center justify-center"-->
<!--    >-->
<!--      <h1 class="display-2 text-center">-->
<!--        You are not allowed to view this page-->
<!--      </h1>-->
<!--    </div>-->
    <div class="home">
    <v-container fluid class="bg-white mt-65">
       <div class="d-flex">
            <h1 class="primetime ">Complains</h1>
             <div class="ml-auto mr-4">
              <manage-complain v-if="roleId === 3" @dataUpdated="getData()" :id="editId" :show="formShow" @cancelForm="formShow = false"/>
             </div>
        </div>
        <v-row>
              <v-col cols="12" md="12" class="text-right mt-2">
                  <h5>Showing : {{this.showingFrom? this.showingFrom : "0"}} - {{this.showingTo? this.showingTo: "0"}} of {{this.count? this.count : "0"}}</h5>
              </v-col>
        </v-row>
    <confirmation :confirming="deletingId" @confirmed="remove" :show="deleteConfirmationShow" @canceled="deleteConfirmationShow = false"/>
    <v-simple-table class="mt-4" v-if="complains">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">#</th>
          <th class="text-left">Customer</th>
          <th class="text-left">Subject</th>
          <th class="text-left">Message</th>
          <th class="text-left">Created At</th>
<!--          <th class="text-left">Status</th>-->
          <th class="text-left">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in complains" :key="item.id">
          <td>{{ index + 1 + ( pagination.current - 1 ) * 20 }}</td>
          <td>{{ item.customer_name }}</td>
          <td>
              {{ item.subject }}
          </td>
          <td>{{ item.message }}</td>
          <td>{{ item.created_at }}</td>
          <td>
            <v-btn text :to="`/complains/${item.id}`">
              <v-icon>mdi-eye-outline</v-icon>
            </v-btn>
            <v-btn v-if="roleId === 0 || roleId === 1" text @click="destroy(item.id)" color="red">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <vue-content-loading :width="300" :height="100" v-else>
      <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
    </vue-content-loading>

    <v-row justify="center" style="margin: 0;">
      <v-col cols="8">
        <v-container class="max-width">
          <v-pagination
                  v-model="pagination.current"
                  :length="pagination.total"
                  class="my-4"
                  :total-visible="7"
                  circle
                  @input="getData(pagination.current,filtersUrl())"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>
    </v-container>
  </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ManageComplain from "@/views/complain/Manage.vue";
import Api from "@/services/Api";
import Confirmation from "@/components/Confirmation.vue";
import { VueContentLoading } from 'vue-content-loading';

export default {
  name: 'Complains',
  components: {
      Confirmation,
      ManageComplain,
      VueContentLoading
  },
  data() {
    return {
      roleId: null,
      permissions: JSON.parse(localStorage.getItem('permissions')),
      editId: null,
      deletingId: null,
      formShow: false,
      deleteConfirmationShow: false,
      complains: {},
      filters:{},
      pagination: {
          current: 1,
          total: 0
      },
      statusFilter:[
         {id:0,text:'Inactive'},
         {id:1,text:'Active'},
      ],
      filtering:false,
      loading: false,
      showingFrom:0,
      showingTo:0,
      count:0
    };
  },
  methods: {
      getData(page,searchQuery = "") {
          let current_page = page;
          if (!current_page) {
              current_page = this.pagination.current;
          }
          if(searchQuery) {
              var searchQuery = `&${searchQuery}`;
          }

          Api.get(`/complains?page=${current_page}${searchQuery}`).then(res => {
              this.complains = res.data.data;
              this.pagination.current = res.data.current_page;
              this.pagination.total = res.data.last_page;
              this.showingFrom = res.data.from;
              this.showingTo = res.data.to;
              this.count = res.data.total;
          }).catch(err => {
            if (err.response.status !== 401) {
              this.$toastr.e('Failed to load data!'+ err)
              this.loading = false;
            }else{
              this.$$toastr.e(err.response.data.status);
              this.loading = false;
            }
          });

          this.editId = null;
          this.deletingId = null;
      },
      remove() {
          Api.delete(`/complains/${this.deletingId}`).then(res => {
              this.getData();
          }).catch(err => {
              this.$toastr.e('Failed to load data!'+ err)
              this.loading = false;
          });

          this.editId = null;
          this.deletingId = null;
          this.formShow = false;
      },
      edit(id) {
          this.editId = id;
          this.formShow = true;
      },
      destroy(id) {
          this.deletingId = id;
          this.deleteConfirmationShow = true;
      },
      filtersUrl() {
        var str = "";
        for (var key in this.filters) {
            if (str != "") {
                str += "&";
            }
            str += key + "=" + encodeURIComponent(this.filters[key]);
        }
        return str;
        },
      searchFilter(){
         this.getData(1, this.filtersUrl());
      },
      clearFilter() {
          this.filters = {};
          this.getData();
      }

  },
  mounted() {
    this.roleId = parseInt( JSON.parse( localStorage.getItem("user") ).role );
    this.getData();
  }
}
</script>

<style scoped>

.home {
  background-color: #f8f8fb;
}
th{
  width: 227px !important;
}

</style>
<template>
  <div class="cosignee pb-10" style="background-color: #f8f8fb">
    <breadcrumbs />
    <v-container v-if="exportes && Object.keys(exportes).length">
      <div class="d-flex" style="flex-wrap: wrap">
        <manage
          :detailspage="true"
          @dataUpdated="show()"
          ref="manages"
          :show="formShow"
          @cancelForm="formShow = false"
        />

        <v-btn
          v-if="permissions['exports.update'] === true"
          class="text-capitalize white--text mt-2 py-5"
          small
          color="#2AAA79"
          elevation="0"
          @click="edit(exportes.id)"
          >Update
        </v-btn>

        <details-modal
          :show="updateDetals"
          @clearform="updateDetals = !updateDetals"
          :details="details"
        />
        <v-btn
          class="text-capitalize white--text mt-2 ml-2 py-5"
          small
          color="#026D7B"
          elevation="0"
          @click="
            modalDetails({
              openPdf: `/exports/${exportes.id}/dock-receipt-pdf`,
              api: `exports/${exportes.id}/dock-receipt-modal`,
            })
          "
          >Dock Receipt
        </v-btn>
        <v-btn
          class="text-capitalize white--text mt-2 ml-2 py-5"
          small
          color="#026D7B"
          elevation="0"
          @click="
            modalDetails({
              api: `exports/${exportes.id}/houston-cover-letter-modal`,
            })
          "
          >Houston Cover Latter
        </v-btn>
        <v-btn
          class="text-capitalize white--text mt-2 ml-2 py-5"
          small
          color="#026D7B"
          elevation="0"
          @click="
            modalDetails({
              api: `exports/${exportes.id}/custom-cover-letter-modal`,
            })
          "
          >Custom Cover Latter
        </v-btn>
        <v-btn
          class="text-capitalize white--text mt-2 ml-2 py-5"
          small
          color="#026D7B"
          elevation="0"
          @click="
            modalDetails({
              email: `/exports/${exportes.id}/manifest-modal?email=1`,
              openPdf: `/exports/${exportes.id}/manifest-pdf`,
              api: `exports/${exportes.id}/manifest-modal`,
            })
          "
          >Manifest
        </v-btn>
        <v-btn
          class="text-capitalize white--text mt-2 ml-2 py-5"
          small
          color="#026D7B"
          elevation="0"
          @click="
            modalDetails({
              email: `/exports/${exportes.id}/landing-pdf`,
              openPdf: `/exports/${exportes.id}/landing-pdf`,
              api: `exports/${exportes.id}/landing-modal`,
            })
          "
          >Bill of Lading
        </v-btn>
        <v-btn
          class="text-capitalize white--text mt-2 ml-2 py-5"
          small
          color="#026D7B"
          elevation="0"
          @click="modalDetails({ api: `/exports/${exportes.id}/nonhaz-modal` })"
          >Non-Haz
        </v-btn>
      </div>
    </v-container>
    <vue-content-loading :width="250" :height="100" v-if="loading">
      <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
      <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
    </vue-content-loading>
    <div v-if="exportes && Object.keys(exportes).length">
      <v-container
        class="white mt-5 pl-6 d-flex justify-space-between align-center"
      >
        <h3 class="primetime">Export Details</h3>
      </v-container>
      <v-container v-if="loading == true" class="white mt-1 pt-5 px-5">
        <v-skeleton-loader type="table-tbody@2"></v-skeleton-loader>
      </v-container>
      <v-container v-else class="white mt-1 pt-5 px-5">
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Export Date
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ exportes.export_date ? exportes.export_date : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Loading Date
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ exportes.loading_date ? exportes.loading_date : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Broker Name
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ exportes.broker_name ? exportes.broker_name : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Booking No.
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ exportes.booking_number ? exportes.booking_number : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                ETA
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ exportes.eta }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                AR No.
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ exportes.ar_number ? exportes.ar_number : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                XTN No
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ exportes.xtn_number ? exportes.xtn_number : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Seal No
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ exportes.seal_number ? exportes.seal_number : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Container No
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{
                  exportes.container_number ? exportes.container_number : "N/A"
                }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Cut Off
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ exportes.cutt_off ? exportes.cutt_off : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Vessel
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ exportes.vessel ? exportes.vessel : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Voyage
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ exportes.voyage ? exportes.voyage : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Manifest Date
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ exportes.manifest_date ? exportes.manifest_date : "N/A" }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="table-wrapper"
              v-if="exportes.status == 7"
            >
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Handed Over Date
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{
                  exportes.handed_over_date ? exportes.handed_over_date : "N/A"
                }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Destination
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ exportes.destination ? exportes.destination : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Terminal
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ exportes.terminal ? exportes.terminal : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Streamship Line
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{
                  exportes.streamship_line ? exportes.streamship_line : "N/A"
                }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                ITN
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ exportes.itn ? exportes.itn : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Container Type
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{
                  exportes.container_type_name
                    ? exportes.container_type_name
                    : "N/A"
                }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Port of Loading
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{
                  exportes.port_of_loading_name
                    ? exportes.port_of_loading_name
                    : "N/A"
                }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Port of Discharge
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{
                  exportes.port_of_discharge_name
                    ? exportes.port_of_discharge_name
                    : "N/A"
                }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                BOL Note
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ exportes.bol_note ? exportes.bol_note : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                BOL Remarks
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ exportes.bol_remarks ? exportes.bol_remarks : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Special Instruction
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{
                  exportes.special_instruction
                    ? exportes.special_instruction
                    : "N/A"
                }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Note
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ exportes.note ? exportes.note : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                OTI Number
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ exportes.oti_number ? exportes.oti_number : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Contact Details
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{
                  exportes.contact_details ? exportes.contact_details : "N/A"
                }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-container
        class="white mt-5 pl-6 d-flex justify-space-between align-center"
      >
        <h3 class="primetime">Houston Custom Cover Letter</h3>
      </v-container>
      <v-container v-if="loading == true" class="white mt-1 pt-5 px-5">
        <v-skeleton-loader type="table-tbody@2"></v-skeleton-loader>
      </v-container>
      <v-container v-else class="white mt-1 pt-5 px-5">
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Export Date
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{ exportes.export_date ? exportes.export_date : "N/A" }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Vehicle Location
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{
                  exportes &&
                  exportes.houstan_custom_cover_letter &&
                  exportes.houstan_custom_cover_letter.vehicle_location
                    ? exportes.houstan_custom_cover_letter.vehicle_location
                    : "N/A"
                }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Exporter ID
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{
                  exportes &&
                  exportes.houstan_custom_cover_letter &&
                  exportes.houstan_custom_cover_letter.exporter_id
                    ? exportes.houstan_custom_cover_letter.exporter_id
                    : "N/A"
                }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Exporter Type Issuer
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{
                  exportes.houstan_custom_cover_letter.exporter_type_issuer
                    ? exportes.houstan_custom_cover_letter.exporter_type_issuer
                    : "N/A"
                }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Transportation Value
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{
                  exportes.houstan_custom_cover_letter.transportation_value
                    ? exportes.houstan_custom_cover_letter.transportation_value
                    : "N/A"
                }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Exporter DOB
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{
                  exportes.houstan_custom_cover_letter.exporter_dob
                    ? exportes.houstan_custom_cover_letter.exporter_dob
                    : "N/A"
                }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Ultimate Consignee DOB
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{
                  exportes.houstan_custom_cover_letter.ultimate_consignee_dob
                    ? exportes.houstan_custom_cover_letter
                        .ultimate_consignee_dob
                    : "N/A"
                }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Consignee
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{
                  exportes.houstan_custom_cover_letter.consignee_detail.name
                    ? exportes.houstan_custom_cover_letter.consignee_detail.name
                    : "N/A"
                }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Notify Party
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{
                  exportes.houstan_custom_cover_letter.notify_party_detail.name
                    ? exportes.houstan_custom_cover_letter.notify_party_detail
                        .name
                    : "N/A"
                }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                Manifest Consignee Detail
              </v-col>
              <v-col cols="12" sm="6" class="pa-3 second-column">
                {{
                  exportes.houstan_custom_cover_letter.menifest_consignee_detail
                    .name
                    ? exportes.houstan_custom_cover_letter
                        .menifest_consignee_detail.name
                    : "N/A"
                }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-container
        class="white mt-5 pl-6 d-flex justify-space-between align-center"
      >
        <h3 class="primetime">Customer Information</h3>
      </v-container>
      <v-container v-if="loading == true" class="white mt-1 pt-5 px-5">
        <v-skeleton-loader type="table-tbody@2"></v-skeleton-loader>
      </v-container>
      <v-container v-else class="white mt-1 pt-5 px-5">
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="4" class="pa-3 font-weight-bold">
                Customer Name
              </v-col>
              <v-col cols="12" sm="8" class="pa-3 second-column">
                {{
                  exportes.customer.customer_name
                    ? exportes.customer.customer_name
                    : "N/A"
                }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="4" class="pa-3 font-weight-bold">
                Customer ID
              </v-col>
              <v-col cols="12" sm="8" class="pa-3 second-column">
                {{
                  exportes.customer.legacy_customer_id
                    ? exportes.customer.legacy_customer_id
                    : "N/A"
                }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="4" class="pa-3 font-weight-bold">
                Company Name
              </v-col>
              <v-col cols="12" sm="8" class="pa-3 second-column">
                {{
                  exportes.customer.company_name
                    ? exportes.customer.company_name
                    : "N/A"
                }}
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="table-wrapper">
              <v-col cols="12" sm="4" class="pa-3 font-weight-bold">
                Shipper Name
              </v-col>
              <v-col cols="12" sm="8" class="pa-3 second-column">
                {{ exportes.shipper_name ? exportes.shipper_name : "N/A" }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <export-vehicles :vehicles="vehicles" />
    </div>

    <div v-if="!loading">
      <v-container
        class="white mt-5 pl-6 d-flex justify-space-between align-center"
      >
        <h3 class="primetime">Export Images</h3>
        <div class="mt-5 mt-sm-0">
          <v-btn
            @click="removeAllContainerImage(1)"
            class="mr-3 download-btn"
            color="red"
            dark
            :loading="downloading['export_images']"
            v-if="exportes.container_images.length && permissions['exports.update'] === true"
          >
            <v-icon class="mr-3">mdi-delete-circle</v-icon>
            Delete All Photos
          </v-btn>
          <v-btn
            text
            outlined
            @click="downloadSub(1, 'export_images')"
            class="mr-3 download-btn"
            color="blue"
            :loading="downloading['export_images']"
            v-if="exportes.container_images.length"
          >
            <v-icon class="mr-3">mdi-cloud-download-outline</v-icon>
            Download
          </v-btn>
          <v-btn text color="grey">
            <template>
              <v-row justify="center">
                <v-dialog v-model="dialog1" persistent max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      v-if="permissions['exports.update'] === true"
                    >
                      Add Image
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Add more attachment</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                            <vue-dropzone
                              ref="vueDropzone"
                              id="dropzone"
                              @vdropzone-sending="sendingEvent"
                              @vdropzone-success="storeSuccess"
                              @vdropzone-error="storeError"
                              :options="dropzoneOptions"
                            ></vue-dropzone>
                            <button @click="removeAllFiles">
                              Remove All Files
                            </button>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialog1 = false"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        :disabled="uploading_document"
                        text
                        @click="saveImage(1)"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </template>
          </v-btn>
        </div>
      </v-container>

      <v-container v-if="loading == true" class="white mt-1 pt-5 px-5">
        <v-skeleton-loader type="table-tbody@2"></v-skeleton-loader>
      </v-container>

      <v-container class="white mt-1 pt-5 px-10">
        <v-row>
          <div class="images row" v-viewer >
            <div class="col-2 image_div" v-for="{ thumbnail, url, id } in exportes.container_images" :key="id">
              <img
                  class="col-12 img-border lighten-2"
                  :key="thumbnail"
                  :src="url"
              />
              <button
                  @click="removeContainerImage(id)"
                  class="mr-3 icon_class "
                  v-if="permissions['exports.update'] === true"
              >
                <v-icon class="mr-3" color="red">mdi-close-circle</v-icon>
              </button>
            </div>

          </div>
          <!-- component -->
          <viewer :images="exportes.container_images">
            <img
              v-for="{ thumbnail } in images"
              :key="thumbnail"
              :src="thumbnail"
            />
          </viewer>
        </v-row>
      </v-container>

      <v-container
        class="white mt-5 pl-6 d-flex justify-space-between align-center"
      >
        <h3 class="primetime">Empty Container Images</h3>
        <div class="mt-5 mt-sm-0">
          <v-btn
              @click="removeAllContainerImage(2)"
              class="mr-3 download-btn"
              color="red"
              dark
              :loading="downloading['empty_container_images']"
              v-if="exportes.empty_container_photos.length && permissions['exports.update'] === true"
          >
            <v-icon class="mr-3">mdi-delete-circle</v-icon>
            Delete All Photos
          </v-btn>
          <v-btn
            text
            outlined
            @click="downloadSub(2, 'empty_container_images')"
            class="mr-3 download-btn"
            color="blue"
            :loading="downloading['empty_container_images']"
            v-if="exportes.empty_container_photos.length"
          >
            <v-icon class="mr-3">mdi-cloud-download-outline</v-icon>
            Download
          </v-btn>
          <v-btn text color="grey">
            <template>
              <v-row justify="center">
                <v-dialog v-model="dialog2" persistent max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      v-if="permissions['exports.update'] === true"
                    >
                      Add Image
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Add more attachment</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                            <vue-dropzone
                              ref="vueDropzone"
                              id="dropzone"
                              @vdropzone-sending="sendingEvent"
                              @vdropzone-success="storeSuccess"
                              @vdropzone-error="storeError"
                              :options="dropzoneOptions"
                            ></vue-dropzone>
                            <button @click="removeAllFiles">
                              Remove All Files
                            </button>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialog2 = false"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="uploading_document"
                        @click="saveImage(2)"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </template>
          </v-btn>
        </div>
      </v-container>

      <v-container v-if="loading == true" class="white mt-1 pt-5 px-5">
        <v-skeleton-loader type="table-tbody@2"></v-skeleton-loader>
      </v-container>

      <v-container class="white mt-1 pt-5 px-10">
        <v-row>
          <div class="images row" v-viewer>
<!--            <img-->
<!--              v-for="{ thumbnail, url } in exportes.empty_container_photos"-->
<!--              class="col-2 img-border lighten-2"-->
<!--              :key="thumbnail"-->
<!--              :src="url"-->
<!--            />-->

            <div class="col-2 image_div" v-for="{ thumbnail, url, id } in exportes.empty_container_photos" :key="id">
              <img
                  class="col-12 img-border lighten-2"
                  :key="thumbnail"
                  :src="url"
              />
              <button
                  @click="removeContainerImage(id)"
                  class="mr-3 icon_class "
                  v-if="permissions['exports.update'] === true"
              >
                <v-icon class="mr-3" color="red">mdi-close-circle</v-icon>
              </button>
            </div>

          </div>
          <!-- component -->
          <viewer :images="exportes.empty_container_photos">
            <img
              v-for="{ thumbnail } in images"
              :key="thumbnail"
              :src="thumbnail"
            />
          </viewer>
        </v-row>
      </v-container>

      <v-container
        class="white mt-5 pl-6 d-flex justify-space-between align-center"
      >
        <h3 class="primetime">Loading Photos</h3>
        <div class="mt-5 mt-sm-0">
          <v-btn
              @click="removeAllContainerImage(3)"
              class="mr-3 download-btn"
              color="red"
              dark
              :loading="downloading['loading_photos']"
              v-if="exportes.loading_photos.length && permissions['exports.update'] === true"
          >
            <v-icon class="mr-3">mdi-delete-circle</v-icon>
            Delete All Photos
          </v-btn>

          <v-btn
            text
            outlined
            @click="downloadSub(3, 'loading_photos')"
            class="mr-3 download-btn"
            color="blue"
            :loading="downloading['loading_photos']"
            v-if="exportes.loading_photos.length"
          >
            <v-icon class="mr-3">mdi-cloud-download-outline</v-icon>
            Download
          </v-btn>
          <v-btn text color="grey">
            <template>
              <v-row justify="center">
                <v-dialog v-model="dialog3" persistent max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      v-if="permissions['exports.update'] === true"
                    >
                      Add Image
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Add more attachment</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                            <vue-dropzone
                              ref="vueDropzone"
                              id="dropzone"
                              @vdropzone-sending="sendingEvent"
                              @vdropzone-success="storeSuccess"
                              @vdropzone-error="storeError"
                              :options="dropzoneOptions"
                            ></vue-dropzone>
                            <button @click="removeAllFiles">
                              Remove All Files
                            </button>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialog3 = false"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="uploading_document"
                        @click="saveImage(3)"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </template>
          </v-btn>
        </div>
      </v-container>

      <v-container v-if="loading == true" class="white mt-1 pt-5 px-5">
        <v-skeleton-loader type="table-tbody@2"></v-skeleton-loader>
      </v-container>

      <v-container class="white mt-1 pt-5 px-10">
        <v-row>
          <div class="images row" v-viewer>
<!--            <img-->
<!--              v-for="{ thumbnail, url } in exportes.loading_photos"-->
<!--              class="col-2 img-border lighten-2"-->
<!--              :key="thumbnail"-->
<!--              :src="url"-->
<!--            />-->

            <div class="col-2 image_div" v-for="{ thumbnail, url, id } in exportes.loading_photos" :key="id">
              <img
                  class="col-12 img-border lighten-2"
                  :key="thumbnail"
                  :src="url"
              />
              <button
                  @click="removeContainerImage(id)"
                  class="mr-3 icon_class "
                  v-if="permissions['exports.update'] === true"
              >
                <v-icon class="mr-3" color="red">mdi-close-circle</v-icon>
              </button>
            </div>

          </div>
          <!-- component -->
          <viewer :images="exportes.loading_photos">
            <img
              v-for="{ thumbnail } in images"
              :key="thumbnail"
              :src="thumbnail"
            />
          </viewer>
        </v-row>
      </v-container>

      <v-container
        class="white mt-5 pl-6 d-flex justify-space-between align-center"
      >
        <h3 class="primetime">Loaded Photos</h3>

        <div class="mt-5 mt-sm-0">

          <v-btn
              @click="removeAllContainerImage(4)"
              class="mr-3 download-btn"
              color="red"
              dark
              :loading="downloading['loaded_photos']"
              v-if="exportes.loaded_photos.length && permissions['exports.update'] === true"
          >
            <v-icon class="mr-3">mdi-delete-circle</v-icon>
            Delete All Photos
          </v-btn>

          <v-btn
            text
            outlined
            @click="downloadSub(4, 'loaded_photos')"
            class="mr-3 download-btn"
            color="blue"
            :loading="downloading['loaded_photos']"
            v-if="exportes.loaded_photos.length"
          >
            <v-icon class="mr-3">mdi-cloud-download-outline</v-icon>
            Download
          </v-btn>
          <v-btn text color="grey">
            <template>
              <v-row justify="center">
                <v-dialog v-model="dialog4" persistent max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      v-if="permissions['exports.update'] === true"
                    >
                      Add Image
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Add more attachment</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                            <vue-dropzone
                              ref="vueDropzone"
                              id="dropzone"
                              @vdropzone-sending="sendingEvent"
                              @vdropzone-success="storeSuccess"
                              @vdropzone-error="storeError"
                              :options="dropzoneOptions"
                            ></vue-dropzone>
                            <button @click="removeAllFiles">
                              Remove All Files
                            </button>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialog4 = false"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="uploading_document"
                        @click="saveImage(4)"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </template>
          </v-btn>
        </div>
      </v-container>

      <v-container v-if="loading == true" class="white mt-1 pt-5 px-5">
        <v-skeleton-loader type="table-tbody@2"></v-skeleton-loader>
      </v-container>

      <v-container class="white mt-1 pt-5 px-10">
        <v-row>
          <div class="images row" v-viewer>
<!--            <img-->
<!--              v-for="{ thumbnail, url } in exportes.loaded_photos"-->
<!--              class="col-2 img-border lighten-2"-->
<!--              :key="thumbnail"-->
<!--              :src="url"-->
<!--            />-->

            <div class="col-2 image_div" v-for="{ thumbnail, url, id } in exportes.loaded_photos" :key="id">
              <img
                  class="col-12 img-border lighten-2"
                  :key="thumbnail"
                  :src="url"
              />
              <button
                  @click="removeContainerImage(id)"
                  class="mr-3 icon_class "
                  v-if="permissions['exports.update'] === true"
              >
                <v-icon class="mr-3" color="red">mdi-close-circle</v-icon>
              </button>
            </div>

          </div>
          <!-- component -->
          <viewer :images="exportes.loaded_photos">
            <img
              v-for="{ thumbnail } in images"
              :key="thumbnail"
              :src="thumbnail"
            />
          </viewer>
        </v-row>
      </v-container>

      <v-container
        class="white mt-5 pl-6 d-flex justify-space-between align-center"
      >
        <h3 class="primetime">Export Invoice</h3>
      </v-container>
      <v-container v-if="loading == true" class="white mt-1 pt-5 px-5">
        <v-skeleton-loader type="table-tbody@2"></v-skeleton-loader>
      </v-container>

      <v-container v-else class="white mt-1 pt-5 px-5">
        <v-row>
          <v-col
            v-if="exportes.export_invoice_photo"
            cols="12"
            sm="6"
            class="pa-6"
          >
            <iframe
              :src="exportes.export_invoice_photo[0].url"
              style="width: 100%; height: 250px; border: none"
              frameborder="0"
            ></iframe>
            <br />
            <a
              :href="exportes.export_invoice_photo[0].url"
              target="_blank"
              v-if="exportes.export_invoice"
              >Download</a
            >
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import downloadFile from "@/helpers/downloadFile";
import Api from "@/services/Api";
import Breadcrumbs from "@/views/exports/Breadcrumbs.vue";
import Manage from "@/views/exports/Manage.vue";
import detailsModal from "@/views/exports/detailsModal.vue";
import { VueContentLoading } from "vue-content-loading";
import vue2Dropzone from "vue2-dropzone";
import ExportVehicles from "../../components/export/ExportVehicles.vue";


export default {
  name: "Export",
  components: {
    Breadcrumbs,
    ExportVehicles,
    Manage,
    vueDropzone: vue2Dropzone,
    detailsModal,
    VueContentLoading,
  },
  data() {
    return {
      permissions: JSON.parse(localStorage.getItem("permissions")),
      exportes: {},
      vehicles: [],
      loading: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      base_url: process.env.VUE_APP_API_ENDPOINT,
      invoice_image: "",
      formShow: false,
      updateDetals: false,
      details: {},
      form: {
        fileUrls: [],
      },
      dropzoneOptions: {
        url: null,
        thumbnailWidth: 150,
        maxFilesize: 10,
        autoProcessQueue: true,
        addRemoveLinks: true,
        acceptedFiles: ".jpg,.jpeg,.png",
      },
      exportLoadedGallary: false,
      currentUrl: null,
      exportLoadingGallary: false,
      exportEmptyContainerGallary: false,
      exportContainerGallary: false,
      exportExportGallary: false,
      exportContainerImageGallary: false,
      updateId: "",
      uploading_document: false,
      downloading: {
        export_images: false,
        empty_container_images: false,
        loading_photos: false,
        loaded_photos: false,
      },
    };
  },
  methods: {
    show() {
      this.loading = true;
      this.errors = {};
      Api.get(`/exports/${this.$route.params.id}`)
        .then((res) => {

          this.exportes = res.data.data.export_details;
          this.vehicles = res.data.data.vehicles;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response.status !== 401) {
            this.$toastr.e("Export Not Found! ");
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },
    loadingManage(value, index) {
      this.downloading[index] = value;
    },
    downloadSub(e, index) {
      this.loadingManage(true, index);
      const baseUrl = process.env.VUE_APP_API_ENDPOINT;
      // window.open(
      //   `${baseUrl}/api/v1/exports/${this.$route.params.id}/download-photos?type=${e}`,
      //   "_blank"
      // );
      downloadFile(
        `${baseUrl}/api/v1/exports/${this.$route.params.id}/download-photos?type=${e}`,
        "customer_export",
        ".zip",
        this.loadingManage,
        index
      );
    },
    modalDetails(e) {
      (this.updateDetals = !this.updateDetals), (this.details = e);
    },
    sendingEvent(file, xhr, formData) {
      this.uploading_document = true;
    },
    storeSuccess(file, response) {
      this.uploading_document = false;
      this.loading = false;
      this.form.fileUrls.push(response.data);
    },
    edit(id) {
      this.editId = id;
      this.formShow = true;

      this.$refs.manages.edit(id);
    },
    storeError(file, message, xhr) {
      this.uploading_document = false;
      this.dialog = true;
      if (xhr.status === 422) {
        // this.errors = message.errors;
      } else {
        this.dialog = false;
        this.loading = false;
      }
      this.$toastr.e("Failed to create data! " + message.message);
    },
    removeAllFiles() {
      this.form.fileUrls = [];
      this.$refs.vueDropzone.removeAllFiles();
    },
    saveImage(type) {
      this.errors = {};
      this.form.type = type;
      const dialogVar = "dialog" + type;
      Api.post(`/exports/${this.$route.params.id}/add-more-images`, this.form)
        .then((res) => {
          this[dialogVar] = false;
          this.loading = false;
          this.removeAllFiles();
          this.show();
        })
        .catch((err) => {
          this.removeAllFiles();
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this[dialogVar] = false;
            this.loading = false;
          }
          this.$toastr.e("Failed to create data! " + err);
        });
    },

    removeContainerImage(id) {

      if(confirm('Are you sure to delete ? ')) {
        Api.delete(`/export-images/${id}`)
            .then((res) => {
              this.show();
            })
            .catch((err) => {
              this.$toastr.e("Failed to create data! " + err);
            });
      }
    },

    removeAllContainerImage(type) {

      if(confirm('Are you sure to delete ? ')) {
        Api.post(`/exports/${this.$route.params.id}/delete-images`, { type })
            .then((res) => {
              this.show();
            })
            .catch((err) => {
              this.$toastr.e("Failed to create data! " + err);
            });
      }
    }

  },
  created() {
    this.show();
    this.dropzoneOptions.url = `${Api.defaults.baseURL}/exports/${this.$route.params.id}/photos-upload`;
  },
  mounted() {
    this.invoice_image = this.base_url + "/images/invoice_thumbnil.png";
  },
};
</script>

<style scoped>
.table-wrapper {
  border: 1px solid #dddddd;
}

.second-column {
  border-left: 1px solid #dddddd;
  word-break: break-all;
}

.image_div{
  position: relative;
}
.icon_class {
  position: absolute;
  top: 1px;
  right: -20px;
}
</style>

<template>
    <div class="home">
        <v-container fluid class="bg-white mt-65">
            <div class="d-flex">
                <h1 class="primetime ">Vehicle Claim</h1>
                <v-col class="ml-auto" cols="12" md="2">
                    <manage v-if="buttonShow" @dataUpdated="getData()" :id="editId" :vehicleId="vehicleId" :show="formShow" @cancelForm="formShow = false"/>
                </v-col>
            </div>
            <v-row>
                <v-col cols="12" md="10" class="pb-0 pt-0">
                    <v-container>
                        <v-row>

                        </v-row>
                    </v-container>
                </v-col>

                <v-col cols="12" md="2">

                </v-col>

            </v-row>


            <v-simple-table class="mt-10" v-if="1">
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">#</th>
                        <th class="text-left">Vehicle vin</th>
                        <th class="text-left">Customer</th>
                        <th class="text-left">Location</th>
                        <th class="text-left">Claim Amount</th>
                        <th class="text-left">Create Date</th>
                        <th class="text-left">Claim Status</th>
                        <th class="text-left">Approved Claim Amount</th>
                        <th class="text-left">Approved Date</th>
                        <th class="text-left">Vehicle Part</th>
                        <th class="text-left">Other Parts</th>
                        <th class="text-left">Customer Remarks</th>
                        <th class="text-center">EDIT</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td></td>
                        <td><v-text-field v-model="filters.vin" outlined dense hide-details></v-text-field></td>
                        <td>
                            <v-autocomplete
                                    dense
                                    outlined
                                    v-model="filters.customer_user_id"
                                    :items="customersItem"
                                    item-text="customer_name"
                                    item-value="user_id"
                                    :search-input.sync="customarUserId"
                                    hide-no-data
                                    :loading="loading2"
                                    autocomplete="none"
                                    style="width:200px;margin-top: 25px;"
                            ></v-autocomplete>
                        </td>

                        <td>
                            <v-autocomplete
                                    dense
                                    outlined
                                    v-model="filters.location_id"
                                    :items="localionItem"
                                    item-text="name"
                                    item-value="id"
                                    :search-input.sync="locationId"
                                    autocomplete="none"
                                    hide-no-data
                                    :loading="loading3"
                                    style="width:200px;margin-top: 25px;"
                                    @change="locationSearch"
                            ></v-autocomplete>
                        </td>

                        <td><v-text-field v-model="filters.claim_amount" outlined dense hide-details></v-text-field></td>
                        <td><v-text-field v-model="filters.created_at" outlined dense hide-details></v-text-field></td>
                        <td>
                            <v-select
                                    item-text="title"
                                    item-value="id"
                                    :items="claim_statuses" v-model="filters.claim_status" outlined dense hide-details></v-select>
                        </td>
                        <td><v-text-field v-model="filters.approved_amount" outlined dense hide-details></v-text-field></td>
                        <td><v-text-field v-model="filters.approved_date" outlined dense hide-details></v-text-field></td>
                        <td>
                            <v-select
                                    item-text="title"
                                    item-value="id"
                                    :items="vehicle_parts" v-model="filters.vehicle_part" @change="vehiclePartSearch" outlined dense hide-details></v-select>
                        </td>
                        <td><v-text-field v-model="filters.other_parts" outlined dense hide-details></v-text-field></td>
                        <td><v-text-field v-model="filters.remarks" outlined dense hide-details></v-text-field></td>

                        <td>
                            <v-btn class="mx-2" fab dark small color="primary" :loading="filtering" @click="search">
                                <v-icon dark>mdi-clipboard-text-search</v-icon>
                            </v-btn>
                            <v-btn class="mx-2" fab dark small color="primary" :loading="filtering" @click="clearFilter">
                                <v-icon dark>mdi-close-octagon</v-icon>
                            </v-btn>
                        </td>
                    </tr>

                    <tr v-for="(item,index) in claims.data" :key="index">
                        <td>{{ index+1 }}</td>
                        <td>{{ item.vin }}</td>
                        <td>{{ item.customer_name }}</td>
                        <td>{{ item.location }}</td>
                        <td>{{ item.claim_amount }}</td>
                        <td>{{ item.created_at }}</td>
                        <td>{{ item.claim_status }}</td>
                        <td>{{ item.approved_amount }}</td>
                        <td>{{ item.approved_date }}</td>
                        <td>{{ item.vehicle_part }}</td>
                        <td>{{ item.other_parts }}</td>
                        <td>{{ item.remarks }}</td>
                        <td>
                            <v-btn text :to="`/damage-claims/${item.id}`">
                                <v-icon>mdi-eye-outline</v-icon>
                            </v-btn>
                            <v-btn text @click="edit(item.id)" color="blue">
                                <v-icon>mdi-file-edit</v-icon>
                            </v-btn>
                            <v-btn text @click="destroy(item.id)" color="red">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <vue-content-loading :width="300" :height="100" v-else>
                <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
                <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
                <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
                <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
                <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
                <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
                <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
                <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
                <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
            </vue-content-loading>

          <confirmation :confirming="deletingId" @confirmed="remove" :show="deleteConfirmationShow"
                        @canceled="deleteConfirmationShow = false"/>

            <v-row justify="center" style="margin: 0;">
                <v-col cols="8">
                    <v-container class="max-width">
                        <v-pagination
                                v-model="pagination.current"
                                :length="pagination.total"
                                class="my-4"
                                :total-visible="7"
                                circle
                                @input="getData(pagination.current)"
                        ></v-pagination>
                    </v-container>
                </v-col>
            </v-row>

        </v-container>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Manage from "@/views/claims/Manage.vue";
    import Confirmation from "@/components/Confirmation.vue";
    import Api from "@/services/Api";

    import { VueContentLoading } from 'vue-content-loading';

    export default {
        name: 'Claims',
        components: {
            Manage,
            VueContentLoading,
            Confirmation,
        },

        data() {
            return {
                menu: false,
                modal: false,
                editId: null,
                deletingId: null,
                formShow: false,
                buttonShow:false,
                deleteConfirmationShow: false,
                claims: {},
                pagination: {
                    current: 1,
                    total: 0
                },
                filters: {},
                loading: false,
                loading2: false,
                loading3: false,
                filtering: false,
                statuses: [
                    {'id': '', 'title': 'Select Status'},
                    {'id': 1, 'title': 'Active'},
                    {'id': 2, 'title': 'In-Active'},
                ],
                priceTypes: [
                    {title: 'Select status', id: ''},
                    {title: 'Towing Price', id: 1},
                    {title: 'Ocean Freight Price', id: 2},
                    {title: 'Bike towing', id: 3}
                ],
                vehicle_parts: [
                    {'id': '', 'title': 'Select parts'},
                    {'id': 1, 'title': 'Hood'},
                    {'id': 2, 'title': 'Head Lights'},
                    {'id': 3, 'title': 'Front Bumper'},
                    {'id': 4, 'title': 'Rear Bumper'},
                    {'id': 5, 'title': 'Fender'},
                    {'id': 6, 'title': 'Roof'},
                    {'id': 7, 'title': 'Doors'},
                    {'id': 8, 'title': 'Door handle'},
                    {'id': 9, 'title': 'Bonnet'},
                    {'id': 10, 'title': 'Trunk'},
                    {'id': 11, 'title': 'Gas tank '},
                    {'id': 12, 'title': 'Windshield'},
                    {'id': 13, 'title': 'Rim'},
                    {'id': 14, 'title': 'Tyre'},
                    {'id': 15, 'title': 'Exhaust'},
                    {'id': 16, 'title': 'Indicator'},
                    {'id': 17, 'title': 'Tail light'},
                    {'id': 18, 'title': 'Rear window'},
                    {'id': 19, 'title': 'Grill'},
                    {'id': 20, 'title': 'Keys'},
                ],
                claim_statuses: [
                    {'id': '', 'title': 'Select Status'},
                    {'id': '0', 'title': 'REJECTED'},
                    {'id': 1, 'title': 'APPROVED'},
                    {'id': 10, 'title': 'PENDING'},
                ],
                customersItem: [],
                customarUserId: '',
                localionItem: [],
                locationId: '',
                vehicleId : null,
            }
        },
        methods : {
            
            getData(page, searchQuery = "") {
                this.loading = true;
                if (!page) {
                  page = this.pagination.current;
                }
                if(searchQuery) {
                  searchQuery = `&${searchQuery}`;
                }
                Api.get(`/vehicle-claims?page=${page}${searchQuery}`).then(res => {
                    this.claims = res.data;
                    this.pagination.current = res.data.current_page;
                    this.pagination.total = res.data.last_page;
                    this.loading = false;
                }).catch((err) => {
                    if (err.response.status !== 401) {
                    this.$toastr.e("Failed to load data!" + err);
                    this.loading = false;
                    }else{
                        this.$toastr.e(err.response.data.status)
                    }
                })
            },
            filtersUrl() {
                var str = "";
                for (var key in this.filters) {
                    if (str != "") {
                        str += "&";
                    }
                    str += key + "=" + encodeURIComponent(this.filters[key]);
                }
                return str;
            },

            edit(id) {
                this.buttonShow = true;
                this.editId = id;
                this.formShow = true;
            },
            destroy(id) {
                this.deletingId = id;
                this.deleteConfirmationShow = true;
            },
            remove() {
                Api.delete(`/vehicle-claims/${this.deletingId}`).then(res => {
                    this.getData();
                }).catch((err) => {
                    if (err.response.status !== 401) {
                    this.$toastr.e('Failed to load data!' + err)
                    this.loading = false;
                    }
                });

                this.editId = null;
                this.deletingId = null;
                this.formShow = false;
            },
            search() {
                this.getData(1, this.filtersUrl());
            },

            clearFilter() {
                this.filters = {};
                this.getData();
            },
            locationSearch(){
                // this.search()
            },
            vehiclePartSearch(){
                this.search();
            }
            
        },
        watch:{
            customarUserId(newVal){
                if (newVal) {
                    this.loading2 = true
                    Api.get(`/customers-item?status=1&customer_name=${newVal}`).then(res => {
                        this.loading2 = false
                        this.customersItem = res.data.data;
                    }).catch((err) => {
                        if (err.response.status !== 401) {
                        this.$toastr.e('Failed to load data!'+ err)
                        this.loading2 = false;
                        }
                    });
                }
            },
            locationId(newVal){
                if (newVal) {
                    this.loading3 = true
                    Api.get(`/settings/locations?status=1?name=${newVal}`).then(res => {
                        this.loading3 = false
                        this.localionItem = res.data.data;
                    }).catch((err) => {
                        if (err.response.status !== 401) {
                        this.$toastr.e('Failed to load data!'+ err)
                        this.loading3 = false;
                        }
                    });
                }
            },
          "filters.claim_status"() {
            this.search();
          },
          "filters.location_id"() {
            this.search();
          },
          "filters.customer_user_id"() {
            this.search();
          },
        },
      mounted() {
        if (Object.keys(this.$route.query).length > 0) {
            if (this.$route.query.vehicle_id && this.$route.query.vehicle_id.length > 0) {
                this.vehicleId = this.$route.query.vehicle_id;
                this.formShow = true;
                this.buttonShow = true;
            }
        }

        this.getData();

        if (this.$route.params.id) {
          this.buttonShow = true;
          this.formShow = true;
        }
      },
    }
</script>
<style scoped>

    .home {
        background-color: #f8f8fb;
    }

</style>
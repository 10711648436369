<template>
  <v-row class="mt-1" :class="{ pos: detailspage }">
    <v-dialog scrollable v-model="dialog" max-width="1150px" persistent>
      <template v-slot:activator="{ on, attrs }" style="width: 10px">
        <div v-if="permissions['exports.store'] == false"></div>
        <div v-else>
          <v-btn
            v-if="!detailspage"
            color="primary"
            dark
            v-bind="attrs"
            @click="emptyForm(), (editing = false), makeApi(0)"
            v-on="on"
          >
            New Export
          </v-btn>
        </div>
      </template>
      <v-card>
        <v-card-title style="border-bottom: 1px solid #eeee">
          <span class="headline">{{ editing ? "Edit" : "New" }} Exports</span>
          <v-btn
            class="ml-auto"
            color="blue darken-1"
            text
            @click="cancel(), (editing = false)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container class="export-modal">
            <v-autocomplete
              dense
              outlined
              hide-no-data
              label="Select Vin"
              autocomplete="none"
              v-model="vinSearchId"
              :search-input.sync="searchVinId"
              :items="vinSearch"
              item-text="vin"
              item-value="id"
              style="width: 50%; display: inline-block"
            ></v-autocomplete>
            <v-btn
              depressed
              color="primary"
              class="ml-8"
              @click="vinSearchTableData()"
            >
              Add
            </v-btn>

            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Year</th>
                    <th class="text-left">Make</th>
                    <th class="text-left">Model</th>
                    <th class="text-left">Color</th>
                    <th class="text-left">Vin</th>
                    <th class="text-left">Status</th>
                    <th class="text-left">Title Number</th>
                    <th class="text-left">Location</th>
                    <th class="text-left">Lot Number</th>
                    <th class="text-left">CUSTOMER NAME</th>
                    <th class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(vinTable, index) in vehicles" :key="index">
                    <td>{{ vinTable.year }}</td>
                    <td>{{ vinTable.make }}</td>
                    <td>{{ vinTable.model }}</td>
                    <td>{{ vinTable.color }}</td>
                    <td>{{ vinTable.vin }}</td>
                    <td>{{ vinTable.status_name }}</td>
                    <td>{{ vinTable.title_number }}</td>
                    <td>{{ vinTable.location }}</td>
                    <td>{{ vinTable.lot_number }}</td>
                    <td>{{ vinTable.customer_name }}</td>
                    <td>
                      <v-btn
                        text
                        @click="deletevehicle(vinTable.id)"
                        color="red"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <br />

            <br />

            <div class="export-modal__title">customer Info</div>
            <v-row>
              <v-col cols="12" md="6">
                <v-autocomplete
                  dense
                  outlined
                  v-model="form.customer_user_id"
                  :items="customer"
                  label="CUSTOMER"
                  item-text="customer_name"
                  item-value="user_id"
                  :search-input.sync="customarUserId"
                  no-filter
                >
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            data.item.customer_name +
                            ' (' +
                            data.item.legacy_customer_id +
                            ')'
                          "
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.company_name"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  disabled
                  v-model="customarCompanyName"
                  name="customarCompanyName"
                  label="CUSTOMER COMPANY"
                  outlined
                  dense
                  :error-messages="errors.customerCompany"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="legacyCustomerId"
                  name="legacyCustomerId"
                  :disabled="true"
                  label="Customer ID"
                  :error-messages="errors.legacyCustomerId"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  dense
                  outlined
                  v-model="form.shipper_id"
                  :items="shippers"
                  label="SHIPPER / EXPORTER"
                  item-text="customer_name"
                  item-value="user_id"
                  :search-input.sync="shipperId"
                  no-filter
                >
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            data.item.customer_name +
                            ' (' +
                            data.item.legacy_customer_id +
                            ')'
                          "
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.company_name"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <br />
            <hr />
            <br />

            <div class="export-modal__title">Export Info</div>
            <v-row>
              <v-col cols="12" md="6">
                <v-menu
                  ref="datePicker2"
                  v-model="datePicker2"
                  :error-messages="errors.loading_date"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="form.loading_date"
                      name="loading_date"
                      label="Loading Date"
                      prepend-icon="mdi-calendar"
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="errors.loading_date"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    style="height: 350px"
                    v-model="form.loading_date"
                    @input="datePicker2 = false"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="6">
                <v-menu
                  ref="datePicker1"
                  v-model="datePicker1"
                  :error-messages="errors.export_date"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="form.export_date"
                      name="export_date"
                      label="Export Date"
                      prepend-icon="mdi-calendar"
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="errors.export_date"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    style="height: 350px"
                    v-model="form.export_date"
                    @input="datePicker1 = false"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.broker_name"
                  name="broker_name"
                  label="BROKER NAME"
                  outlined
                  dense
                  :error-messages="errors.broker_name"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.booking_number"
                  name="booking_number"
                  label="BOOKING NUMBER"
                  outlined
                  dense
                  :error-messages="errors.booking_number"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.oti_number"
                  name="oti_number"
                  label="OTI NUMBER"
                  outlined
                  dense
                  :error-messages="errors.oti"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-menu
                  ref="datePickerw"
                  v-model="datePickerw"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :error-messages="errors.eta"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="form.eta"
                      name="eta"
                      label="ETA"
                      prepend-icon="mdi-calendar"
                      clearable
                      :error-messages="errors.eta"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    style="height: 350px"
                    v-model="form.eta"
                    :typeable="true"
                    @input="datePickerw = false"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.ar_number"
                  name="ar_number"
                  label="AR NO"
                  outlined
                  dense
                  :error-messages="errors.ar_number"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.xtn_number"
                  name="xtn_number"
                  label="XTN NO"
                  outlined
                  dense
                  :error-messages="errors.xtn_number"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.seal_number"
                  name="seal_number"
                  label="SEAL NO"
                  outlined
                  dense
                  :error-messages="errors.seal_number"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.container_number"
                  name="container_number"
                  label="CONTAINER NO"
                  outlined
                  dense
                  :error-messages="errors.container_number"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-menu
                  ref="datePicker4"
                  v-model="datePicker4"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="form.cutt_off"
                      name="cutt_off"
                      label="CUT OFF"
                      prepend-icon="mdi-calendar"
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    style="height: 350px"
                    v-model="form.cutt_off"
                    @input="datePicker4 = false"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.vessel"
                  name="vessel"
                  label="VESSEL"
                  outlined
                  dense
                  :error-messages="errors.vessel"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.voyage"
                  name="voyage"
                  label="VOYAGE"
                  outlined
                  dense
                  :error-messages="errors.voyage"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.terminal"
                  name="terminal"
                  label="TERMINAL"
                  outlined
                  dense
                  :error-messages="errors.terminal"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  v-model="form.streamship_line"
                  name="streamship_line"
                  :items="streamship_lines"
                  label="STREAMSHIP LINE"
                  dense
                  outlined
                  :error-messages="errors.streamship_line"
                ></v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.destination"
                  name="destination"
                  label="DESTINATION"
                  outlined
                  dense
                  :error-messages="errors.destination"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.itn"
                  name="itn"
                  label="ITN"
                  outlined
                  dense
                  :error-messages="errors.itn"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.contact_details"
                  name="contact_details"
                  outlined
                  dense
                  label="CONTACT DETAIL"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" v-if="form.status == 7">
                <v-menu
                  ref="datePickerwHN"
                  v-model="datePickerwHN"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :error-messages="errors.handed_over_date"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="form.handed_over_date"
                      name="handed_over_date"
                      label="Handed Over Date"
                      prepend-icon="mdi-calendar"
                      clearable
                      :error-messages="errors.handed_over_date"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    style="height: 350px"
                    v-model="form.handed_over_date"
                    :typeable="true"
                    @input="datePickerwHN = false"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="12">
                <v-textarea
                  v-model="form.note"
                  name="note"
                  outlined
                  label="NOTE"
                  rows="2"
                ></v-textarea>
              </v-col>
            </v-row>

            <br />
            <hr />
            <br />

            <div class="export-modal__title">additional Info</div>
            <v-row>
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  v-model="form.special_instruction"
                  name="special_instruction"
                  label="SPECIAL INSTRUCTION"
                  row-height="10"
                  rows="1"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  dense
                  outlined
                  :items="containerData"
                  item-value="id"
                  item-text="title"
                  v-model="form.container_type"
                  name="container_type"
                  placeholder="CONTAINER TYPE"
                  label="CONTAINER TYPE"
                  :error-messages="errors.container_type"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  dense
                  outlined
                  item-text="name"
                  item-value="id"
                  v-model="form.port_of_loading"
                  name="port_of_loading"
                  :items="portLoadingData"
                  placeholder="PORT OF LOADING"
                  label="PORT OF LOADING"
                  autocomplete="none"
                  :error-messages="errors.port_of_loading"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  dense
                  outlined
                  item-text="name"
                  item-value="id"
                  v-model="form.port_of_discharge"
                  name="port_of_discharge"
                  :items="portDischargeData"
                  placeholder="PORT OF DISCHARGE"
                  label="PORT OF DISCHARGE"
                  autocomplete="none"
                  :loading="containersLoading"
                  :error-messages="errors.port_of_discharge"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.bol_note"
                  name="bol_note"
                  label="BOL NOTE"
                  outlined
                  dense
                  :error-messages="errors.bol_note"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  v-model="form.bol_remarks"
                  name="bol_remarks"
                  label="BOL REMARKS"
                  row-height="10"
                  rows="1"
                ></v-textarea>
              </v-col>
            </v-row>
            <br />
            <hr />
            <br />

            <div class="export-modal__title">dock recipt-more Info</div>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.awb_number"
                  name="awb_number"
                  label="B/L OR AWB NUMBER"
                  outlined
                  dense
                  :error-messages="errors.awb_number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.export_reference"
                  name="export_reference"
                  label="EXPORT REFERENCE"
                  outlined
                  dense
                  :error-messages="errors.export_reference"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  v-model="form.forwarding_agent"
                  name="forwarding_agent"
                  label="FORWARDING AGENT"
                  row-height="10"
                  rows="2"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  v-model="form.domestic_routing_instructions"
                  name="domestic_routing_instructions"
                  label="DOMESTIC ROUTING INSTRUCTION"
                  row-height="10"
                  rows="2"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.pre_carriage_by"
                  name="pre_carriage_by"
                  label="PRE CARRIAGE BY"
                  outlined
                  dense
                  :error-messages="errors.pre_carriage_by"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.place_of_receipt_by_pre_carrier"
                  name="place_of_receipt_by_pre_carrier"
                  label="PLACE OF RECEIPT BY PRE CARRIAGE"
                  outlined
                  dense
                  :error-messages="errors.place_of_receipt_by_pre_carrier"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.final_destination"
                  name="final_destination"
                  label="FINAL DESTINATION"
                  outlined
                  dense
                  :error-messages="errors.final_destination"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.loading_terminal"
                  name="loading_terminal"
                  label="LOADING TERMINAL"
                  outlined
                  dense
                  :error-messages="errors.loading_terminal"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.dock_container_type"
                  name="dock_container_type"
                  label="CONTAINER TYPE"
                  outlined
                  dense
                  :error-messages="errors.container_type"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.number_of_packages"
                  name="number_of_packages"
                  label="NUMBER OF PACKAGES"
                  outlined
                  dense
                  :error-messages="errors.number_of_packages"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.by"
                  name="by"
                  label="BY"
                  outlined
                  dense
                  :error-messages="errors.by"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.exporting_carrier"
                  name="exporting_carrier"
                  label="EXPORTING CARRIER"
                  outlined
                  dense
                  :error-messages="errors.exporting_carrier"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-menu
                  ref="datePicker5"
                  v-model="datePicker5"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="form.date"
                      name="date"
                      label="Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    style="height: 350px"
                    v-model="form.date"
                    @input="datePicker5 = false"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="6">
                <v-menu
                  ref="datePicker6"
                  v-model="datePicker6"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="form.auto_receiving_date"
                      name="auto_receiving_date"
                      label="AUTO RECEIVING DATE"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    style="height: 350px"
                    v-model="form.auto_receiving_date"
                    @input="datePicker6 = false"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="6">
                <v-menu
                  ref="datePicker7"
                  v-model="datePicker7"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="form.auto_cut_off"
                      name="auto_cut_off"
                      label="AUTO CUT OFF"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    style="height: 350px"
                    v-model="form.auto_cut_off"
                    @input="datePicker7 = false"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="6">
                <v-menu
                  ref="datePicker8"
                  v-model="datePicker8"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="form.vessel_cut_off"
                      name="vessel_cut_off"
                      label="VESSEL CUT OFF"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    style="height: 350px"
                    v-model="form.vessel_cut_off"
                    @input="datePicker8 = false"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="6">
                <v-menu
                  ref="datePicker9"
                  v-model="datePicker9"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="form.sale_date"
                      name="sale_date"
                      label="SALE DATE"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    style="height: 350px"
                    v-model="form.sale_date"
                    @input="datePicker9 = false"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <br />
            <hr />
            <br />

            <div class="export-modal__title">houston customs cover latter</div>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.vehicle_location"
                  name="vehicle_location"
                  label="VEHICLE LOCATION"
                  outlined
                  dense
                  :error-messages="errors.vehicle_location"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.exporter_id"
                  name="exporter_id"
                  label="EXPORTER ID"
                  outlined
                  dense
                  :error-messages="errors.exporter_id"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.exporter_type_issuer"
                  name="exporter_type_issuer"
                  label="EXPORTER TYPE ISSUER"
                  outlined
                  dense
                  :error-messages="errors.exporter_type_issuer"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.transportation_value"
                  name="transportation_value"
                  label="TRANSPORTAION VALUE"
                  outlined
                  dense
                  :error-messages="errors.transportation_value"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.exporter_dob"
                  name="exporter_dob"
                  label="EXPORTER DOB"
                  outlined
                  dense
                  :error-messages="errors.exporter_dob"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.ultimate_consignee_dob"
                  name="ultimate_consignee_dob"
                  label="ULTIMATE CONSIGNEE DOB"
                  outlined
                  dense
                  :error-messages="errors.ultimate_consignee_dob"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-autocomplete
                  dense
                  outlined
                  v-model="form.consignee"
                  name="consignee"
                  :items="consignee_detail"
                  item-text="consignee_name"
                  item-value="id"
                  placeholder="CONSIGNEE"
                  autocomplete="none"
                  :error-messages="errors.consignee"
                  :loading="containersLoading"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="6">
                <v-autocomplete
                  dense
                  outlined
                  v-model="form.notify_party"
                  name="notify_party"
                  :items="notify_party_detail"
                  item-text="consignee_name"
                  item-value="id"
                  placeholder="NOTIFY PARTY"
                  autocomplete="none"
                  :loading="containersLoading"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="6">
                <v-autocomplete
                  dense
                  outlined
                  v-model="form.menifest_consignee"
                  name="menifest_consignee"
                  :items="menifest_consignee_detail"
                  item-text="consignee_name"
                  item-value="id"
                  placeholder="MANIFEST CONSIGNEE"
                  autocomplete="none"
                  :loading="containersLoading"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <hr />
            <br />

            <div class="export-modal__title">EXPORT INVOICE</div>
            <v-row>
              <v-col cols="12" md="12">
                <vue-dropzone
                  ref="vueDropzoneExprotInvoice"
                  id="vueDropzoneExprotInvoice"
                  @vdropzone-removed-file="removeFileExprotInvoice"
                  @vdropzone-sending="sendingEventExprotInvoice"
                  @vdropzone-success="storeSuccessExprotInvoice"
                  @vdropzone-error="storeErrorExprotInvoice"
                  :options="dropzoneOptionsExprotInvoice"
                ></vue-dropzone>
                <button @click="removeAllFileExprotInvoice">
                  Remove All Files
                </button>
              </v-col>
            </v-row>
            <br />

            <div class="export-modal__title">CONTAINER IMAGES</div>
            <v-row>
              <v-col cols="12" md="12">
                <vue-dropzone
                  ref="vueDropzoneContinerImage"
                  id="vueDropzoneContinerImage"
                  @vdropzone-removed-file="removeFileContinerImage"
                  @vdropzone-sending="sendingEventContinerImage"
                  @vdropzone-success="storeSuccessContinerImage"
                  @vdropzone-error="storeErrorContinerImage"
                  :options="dropzoneOptionsContinerImage"
                ></vue-dropzone>
                <button @click="removeAllFileContinerImage">
                  Remove All Files
                </button>
              </v-col>
            </v-row>
            <br />

            <div class="export-modal__title">EMPTY CONTAINER IMAGES</div>
            <v-row>
              <v-col cols="12" md="12">
                <vue-dropzone
                  ref="vueDropzoneEmptyContainer"
                  id="vueDropzoneEmptyContainer"
                  @vdropzone-removed-file="removeFileEmptyContainer"
                  @vdropzone-sending="sendingEventEmptyContainer"
                  @vdropzone-success="storeSuccessEmptyContainer"
                  @vdropzone-error="storeErrorEmptyContainer"
                  :options="dropzoneOptionsEmptyContainer"
                ></vue-dropzone>
                <button @click="removeAllFileEmptyContainer">
                  Remove All Files
                </button>
              </v-col>
            </v-row>

            <br />

            <div class="export-modal__title">Loading Photos</div>
            <v-row>
              <v-col cols="12" md="12">
                <vue-dropzone
                  ref="refLoadingPhotos"
                  id="idLoadingPhotos"
                  @vdropzone-removed-file="removeFileLoadingPhotos"
                  @vdropzone-sending="sendingEventLoadingPhotos"
                  @vdropzone-success="storeSuccessLoadingPhotos"
                  @vdropzone-error="storeErrorLoadingPhotos"
                  :options="dropzoneOptionsLoadingPhotos"
                ></vue-dropzone>
                <button @click="removeAllFileLoadingPhotos">
                  Remove All Files
                </button>
              </v-col>
            </v-row>

            <br />

            <div class="export-modal__title">Loaded Photos</div>
            <v-row>
              <v-col cols="12" md="12">
                <vue-dropzone
                  ref="refLoadedPhotos"
                  id="idLoadedPhotos"
                  @vdropzone-removed-file="removeFileLoadedPhotos"
                  @vdropzone-sending="sendingEventLoadedPhotos"
                  @vdropzone-success="storeSuccessLoadedPhotos"
                  @vdropzone-error="storeErrorLoadedPhotos"
                  :options="dropzoneOptionsLoadedPhotos"
                ></vue-dropzone>
                <button @click="removeAllFileLoadedPhotos">
                  Remove All Files
                </button>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="white"
            text
            class="primary"
            :disabled="loading"
            @click="editing ? update() : store()"
          >
            {{ editing ? "Update" : "Save" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Api from "@/services/Api";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

const _token = "Bearer " + localStorage.getItem("authtoken");

export default {
  props: ["show", "detailspage"],
  name: "Manage",
  components: {
    vueDropzone: vue2Dropzone,
  },

  data() {
    return {
      permissions: JSON.parse(localStorage.getItem("permissions")),
      items: [],
      vinSearch: [],
      streamship_lines: [
        "UN",
        "MAERSK",
        "MSC",
        "SAFMARINE",
        "OOCL",
        "ONE",
        "EVERGREEN",
        "YANG MING",
        "HMM",
        "PIL",
        "APL",
        "APM TERMINALS",
        "CMA CGM",
        "COSCO",
        "HAPAG LLOYD",
        "SEALAND",
        "MEDITERRANEAN",
      ],
      containersLoading: false,
      customarIdLoading: false,
      dataId: "",
      editing: false,
      errors: {},
      searchVinId: "",
      customarUserId: "",
      shipperId: "",
      dialog: this.show,
      loading: false,
      legacyCustomerId: "",
      form: {
        empty_container_photos: [],
        customer: [],
        ar_number: "",
        bol_note: "",
        bol_remarks: "",
        booking_number: "",
        broker_name: "",
        container_number: "",
        container_type: "",
        dock_container_type: "",
        vehicle_ids: [],
        customer_user_id: "",
        shipper_id: 7000948,
        cutt_off: "",
        destination: "",
        eta: "",
        export_date: "",
        fileurl: {
          export_invoice_photo: [],
          container_images: [],
          empty_container_photos: [],
          loading_photos: [],
          loaded_photos: [],
        },
        export_invoice: "",
        consignee: "",
        exporter_dob: "",
        exporter_id: "",
        exporter_type_issuer: "",
        notify_party: "",
        transportation_value: "",
        ultimate_consignee_dob: "",
        vehicle_location: "",
        id: "",
        loading_date: "",
        note: "",
        port_of_discharge: "",
        port_of_loading: "",
        seal_number: "",
        special_instruction: "",
        streamship_line: "",
        terminal: "",
        vessel: "",
        xtn_number: "",
        export_invoice_photo: [],
        container_images: [],
        loading_photos: [],
        loaded_photos: [],
        voyage: "",
        itn: "",
        contact_details: "",
        awb_number: "",
        export_reference: "",
        forwarding_agent: "",
        domestic_routing_instructions: "",
        pre_carriage_by: "",
        place_of_receipt_by_pre_carrier: "",
        final_destination: "",
        loading_terminal: "",
        number_of_packages: "",
        by: "",
        exporting_carrier: "",
        date: "",
        auto_receiving_date: "",
        auto_cut_off: "",
        vessel_cut_off: "",
        sale_date: "",
        menifest_consignee: "",
        handed_over_date: "",
      },
      cusotmarAutoComplete: "",
      vinSearchId: "",
      customer: [],
      shippers: [],
      vehicles: [],
      containerData: [
        { id: "1", title: "1 X 20'HC DRY VAN" },
        { id: "2", title: "1 X 45'HC DRY VAN" },
        { id: "3", title: "1 X 40'HC DRY VAN" },
      ],
      portLoadingData: [
        { id: "1", name: "NEW JERSEY, NWJ" },
        { id: "2", name: "HOUSTON, TX" },
        { id: "3", name: "LOS ANGELES, CA" },
        { id: "4", name: "NEWARK, NJ" },
        { id: "5", name: "SAVANNAH, GA" },
        { id: "6", name: "HOUSTON, TEXAS" },
        { id: "7", name: "HONOLULU, HI" },
        { id: "8", name: "BALTIMORE, MD" },
      ],
      portDischargeData: [
        { id: "1", name: "JEBEL ALI, UAE" },
        { id: "2", name: "AQABA, JORDAN" },
        { id: "3", name: "KARACHI, PAKISTAN" },
        { id: "4", name: "SOHAR, OMAN" },
        { id: "5", name: "UMM QASR, IRAQ" },
        { id: "6", name: "MERSIN, TURKEY" },
        { id: "7", name: "CAMBODIA" },
        { id: "8", name: "BAHRAIN" },
        { id: "9", name: "MUSCAT,OMAN" },
        { id: "10", name: "FREETOWN, SIERRA LEONE" },
        { id: "11", name: "TEMA, GHANA" },
        { id: "12", name: "VIETMAN" },
        { id: "13", name: "BUSAN, KOREA" },
        { id: "14", name: "GERMANY" },
        { id: "15", name: "MISURATA, LIBYA" },
        { id: "16", name: "NIGERIA" },
        { id: "17", name: "DENMARK" },
        { id: "18", name: "BENGHAZI, LIBYA" },
        { id: "19", name: "MYANMAR" },
        { id: "20", name: "SALALAH, OMAN" },
        { id: "21", name: "LIBYA - ALKHOMS" },
      ],
      consigneeData: [],
      notifyPartyData: [],
      consignee_detail: [],
      notify_party_detail: [],
      menifest_consignee_detail: [],
      menifestConsigneeData: [],

      costomarInfovalue: "",
      customarCompanyName: "",
      datePicker1: false,
      datePicker2: false,
      datePicker3: false,
      datePicker4: false,
      datePicker5: false,
      datePicker6: false,
      datePicker7: false,
      datePicker8: false,
      datePicker9: false,
      datePicker10: false,
      datePickerw: false,
      datePickerwHN: false,
      dropzoneOptionsExprotInvoice: {
        url: null,
        thumbnailWidth: 150,
        maxFilesize: 10,
        addRemoveLinks: true,
        acceptedFiles: ".pdf",
        maxFiles: 1,
        headers: { Authorization: _token },
      },
      dropzoneOptionsContinerImage: {
        url: null,
        thumbnailWidth: 150,
        addRemoveLinks: true,
        acceptedFiles: ".jpg,.jpeg,.png",
        headers: { Authorization: _token },
      },
      dropzoneOptionsEmptyContainer: {
        url: null,
        thumbnailWidth: 150,
        addRemoveLinks: true,
        acceptedFiles: ".jpg,.jpeg,.png",
        headers: { Authorization: _token },
      },

      dropzoneOptionsLoadingPhotos: {
        url: null,
        thumbnailWidth: 150,
        addRemoveLinks: true,
        acceptedFiles: ".jpg,.jpeg,.png",
        headers: { Authorization: _token },
      },
      dropzoneOptionsLoadedPhotos: {
        url: null,
        thumbnailWidth: 150,
        addRemoveLinks: true,
        acceptedFiles: ".jpg,.jpeg,.png",
        headers: { Authorization: _token },
      },

      fileurl: {
        export_invoice_photo: [],
        container_images: [],
        empty_container_photos: [],
        loading_photos: [],
        loaded_photos: [],
      },

      removeFilesFromDropzoneOnlyExprotInvoice: false,
      removeFilesFromDropzoneOnlyContinerImage: false,
      removeFilesFromDropzoneOnlyEmptyContainer: false,
      removeFilesFromDropzoneOnlyLoadingPhotos: false,
      removeFilesFromDropzoneOnlyLoadedPhotos: false,
    };
  },
  methods: {
    emptyForm() {
      this.legacyCustomerId = "";
      this.vehicles = {};
      this.form = {};
      this.form = {
        customer: [],
        ar_number: "",
        bol_note: "",
        bol_remarks: "",
        booking_number: "",
        broker_name: "",
        container_number: "",
        container_type: "",
        dock_container_type: "",
        vehicle_ids: [],
        customer_user_id: "",
        shipper_id: 7000948,
        cutt_off: "",
        destination: "",
        eta: "",
        export_date: "",
        menifest_consignee: "",
        export_invoice: "",
        consignee: "",
        exporter_dob: "",
        exporter_id: "",
        exporter_type_issuer: "",
        notify_party: "",
        transportation_value: "",
        ultimate_consignee_dob: "",
        vehicle_location: "",
        id: "",
        loading_date: "",
        note: "",
        port_of_discharge: "",
        port_of_loading: "",
        seal_number: "",
        special_instruction: "",
        streamship_line: "",
        terminal: "",
        vessel: "",
        xtn_number: "",

        export_images: [],

        export_invoice_photo: [],
        container_images: [],
        empty_container_photos: [],
        loading_photos: [],
        loaded_photos: [],

        voyage: "",
        itn: "",
        contact_details: "",
        awb_number: "",
        export_reference: "",
        forwarding_agent: "",
        domestic_routing_instructions: "",
        pre_carriage_by: "",
        place_of_receipt_by_pre_carrier: "",
        final_destination: "",
        loading_terminal: "",
        number_of_packages: "",
        by: "",
        exporting_carrier: "",
        date: "",
        auto_receiving_date: "",
        handed_over_date: "",
        auto_cut_off: "",
        vessel_cut_off: "",
        sale_date: "",
        fileurl: {
          export_invoice_photo: [],
          container_images: [],
          empty_container_photos: [],
          loading_photos: [],
          loaded_photos: [],
        },
      };
      this.vehicles = [];
      this.removeAllFileExprotInvoice();
      this.removeAllFileContinerImage();
      this.removeAllFileEmptyContainer();
      this.removeAllFileLoadingPhotos();
      this.removeAllFileLoadedPhotos();
    },
    store() {
      this.errors = {};
      if (this.vehicles.length === 0) {
        this.$toastr.e("No vin selected ! Please select a vin");
        return;
      }
      // let obj = {
      //   fileurl: this.fileurl,
      // };
      // this.merged = Object.assign(this.form, obj);
      this.loading = true;

      const removeArr = [
        "vehicles",
        "export_invoice_photo",
        "container_images",
        "empty_container_photos",
        "loading_photos",
        "loaded_photos",
        "customer",
      ];

      const formObject = this.form;

      removeArr.forEach((e) => {
        delete formObject[e];
      });

      Api.post(`/exports`, formObject)
        .then((res) => {
          this.dialog = false;
          this.loading = false;
          this.emptyForm();
          this.$emit("dataUpdated");
          this.$toastr.s(res.data.message);
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors
              ? err.response.data.errors
              : {};
            this.$toastr.e(
              err.response.data.message
                ? err.response.data.message
                : "One or more required field missing."
            );
          } else {
            this.$toastr.e(
              "Failed to create data! One or more required field missing."
            );
            this.dialog = false;
          }
          this.loading = false;
        });
    },
    // Delete Vin Table item function

    deletevehicle(indexId) {
      let i = this.form.vehicle_ids.findIndex((x) => x === indexId);

      let vehicleIndex = this.vehicles.findIndex((x) => x.id === indexId);

      this.vehicles.splice(vehicleIndex, 1);
      this.form.vehicle_ids.splice(i, 1);
    },

    async update() {
      this.loading = true;
      this.errors = {};
      // let obj = {
      //   fileurl: this.fileurl,
      // };
      // this.merged = Object.assign(this.form, obj);

      const removeArr = [
        "vehicles",
        "export_invoice_photo",
        "container_images",
        "empty_container_photos",
        "loading_photos",
        "loaded_photos",
        "customer",
      ];

      const {
        export_invoice_photo,
        container_images,
        empty_container_photos,
        loading_photos,
        loaded_photos,
        fileurl,
      } = this.form;

      const file_urls = {
        export_invoice_photo: [
          ...fileurl.export_invoice_photo,
          ...export_invoice_photo,
        ],
        container_images: [...fileurl.container_images, ...container_images],
        empty_container_photos: [
          ...fileurl.empty_container_photos,
          ...empty_container_photos,
        ],
        loading_photos: [...fileurl.loading_photos, ...loading_photos],
        loaded_photos: [...fileurl.loaded_photos, ...loaded_photos],
      };

      const formObject = this.form;

      removeArr.forEach((e) => {
        delete formObject[e];
      });

      const finalObject = { ...formObject, fileurl: file_urls };

      await Api.put(`/exports/${this.dataId}`, finalObject)
        .then((res) => {
          this.dialog = false;
          this.loading = false;
          this.emptyForm();
          this.$toastr.s(res.data.message);
          this.$emit("dataUpdated");
          this.$emit("cancelForm");
          this.editing = false;
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
            this.$toastr.e(
              "Failed to update data! One or more required field missing."
            );
          } else {
            this.$toastr.e(
              err.response?.data?.message ??
                "Failed to update data! One or more required field missing."
            );
          }
          this.loading = false;
        });
    },
    editData(db) {
      this.form.ar_number = db.ar_number;
      this.form.bol_note = db.bol_note;
      this.form.bol_remarks = db.bol_remarks;
      this.form.booking_number = db.booking_number;
      this.form.broker_name = db.broker_name;
      this.form.container_number = db.container_number;
      this.form.container_type = db.container_type;
      this.form.vehicle_ids = db.vehicle_ids;
      this.form.customer_user_id = parseInt(db.customer_user_id);
      this.form.shipper_id = parseInt(db.shipper_id);
      this.form.cutt_off = db.cutt_off;
      this.form.destination = db.destination;
      this.form.eta = db.eta;
      this.form.export_date = db.export_date;
      this.form.handed_over_date = db.handed_over_date;
      this.form.status = db.status;
      this.form.export_images = db.export_images;
      this.form.export_invoice = db.export_invoice;
      this.form.consignee = parseInt(db.houstan_custom_cover_letter.consignee);
      this.form.exporter_dob = db.houstan_custom_cover_letter.exporter_dob;
      this.form.exporter_id = db.houstan_custom_cover_letter.exporter_id;
      this.form.exporter_type_issuer =
        db.houstan_custom_cover_letter.exporter_type_issuer;
      this.form.notify_party = parseInt(
        db.houstan_custom_cover_letter.notify_party
      );
      this.form.menifest_consignee = parseInt(
        db.houstan_custom_cover_letter.menifest_consignee
      );
      this.form.transportation_value =
        db.houstan_custom_cover_letter.transportation_value;
      this.form.ultimate_consignee_dob =
        db.houstan_custom_cover_letter.ultimate_consignee_dob;
      this.form.vehicle_location =
        db.houstan_custom_cover_letter.vehicle_location;
      this.form.id = db.id;
      this.form.loading_date = db.loading_date;
      this.form.note = db.note;
      this.form.port_of_discharge = db.port_of_discharge;
      this.form.port_of_loading = db.port_of_loading;
      this.form.seal_number = db.seal_number;
      this.form.special_instruction = db.special_instruction;
      this.form.streamship_line = db.streamship_line;
      this.form.terminal = db.terminal;
      this.form.vessel = db.vessel;
      this.form.xtn_number = db.xtn_number;
      this.form.export_invoice_photo = db.export_invoice_photo ?? [];
      this.form.container_images = db.container_images ?? [];
      this.form.empty_container_photos = db.empty_container_photos ?? [];
      this.form.loading_photos = db.loading_photos ?? [];
      this.form.loaded_photos = db.loaded_photos ?? [];
      //
      this.form.voyage = db.voyage;
      this.form.contact_details = db.contact_details;
      this.form.oti_number = db.oti_number;
      this.form.itn = db.itn;
      //
      this.form.dock_container_type = db.dock_receipt.dock_container_type;
      this.form.awb_number = db.dock_receipt.awb_number;
      this.form.export_reference = db.dock_receipt.export_reference;
      this.form.forwarding_agent = db.dock_receipt.forwarding_agent;
      this.form.domestic_routing_instructions =
        db.dock_receipt.domestic_routing_instructions;
      this.form.pre_carriage_by = db.dock_receipt.pre_carriage_by;
      this.form.place_of_receipt_by_pre_carrier =
        db.dock_receipt.place_of_receipt_by_pre_carrier;
      this.form.final_destination = db.dock_receipt.final_destination;
      this.form.loading_terminal = db.dock_receipt.loading_terminal;
      this.form.number_of_packages = db.dock_receipt.number_of_packages;
      this.form.by = db.dock_receipt.by;
      this.form.exporting_carrier = db.dock_receipt.exporting_carrier;
      this.form.date = db.dock_receipt.date;
      this.form.auto_receiving_date = db.dock_receipt.auto_receiving_date;
      this.form.auto_cut_off = db.dock_receipt.auto_cut_off;
      this.form.vessel_cut_off = db.dock_receipt.vessel_cut_off;
      this.form.sale_date = db.dock_receipt.sale_date;
      this.form.handed_over_date = db.handed_over_date;
      this.form.fileurl = {
        export_invoice_photo: [],
        container_images: [],
        empty_container_photos: [],
        loading_photos: [],
        loaded_photos: [],
      };
    },
    async edit(id) {
      if (!id) {
        return;
      }
      this.dataId = id;
      this.loading = true;
      this.editing = true;
      this.makeApi(id);
      this.removeAllFileExprotInvoice();
      this.removeAllFileContinerImage();
      this.removeAllFileEmptyContainer();
      this.removeAllFileLoadingPhotos();
      this.removeAllFileLoadedPhotos();

      this.errors = {};
      await Api.get(`/exports/${id}`)
        .then((res) => {
          this.form = res.data.data;
          this.editData(res.data.data.export_details);
          Api.get(`customers-item?user_id=${this.form.customer_user_id}`).then(
            (res) => {
              this.customer = res.data.data;
              this.exports = res.data.data;
            }
          );
          this.vehicles = res.data.data.vehicles ? res.data.data.vehicles : [];
          this.customarCompanyName =
            res.data.data.export_details.customer.company_name;
          this.legacyCustomerId =
            res.data.data.export_details.customer.legacy_customer_id;

          if (this.form.empty_container_photos.length > 0) {
            this.form.empty_container_photos.map((value) => {
              let file = {
                size: value.size,
                name: value.name,
                type: value.type,
              };
              this.$refs.vueDropzoneEmptyContainer.manuallyAddFile(
                file,
                value.url
              );
            });
          }
          if (this.form.container_images.length > 0) {
            this.form.container_images.map((value) => {
              let file = {
                size: value.size,
                name: value.name,
                type: value.type,
              };
              this.$refs.vueDropzoneContinerImage.manuallyAddFile(
                file,
                value.url
              );
            });
          }

          if (this.form.loading_photos.length > 0) {
            this.form.loading_photos.map((value) => {
              let file = {
                size: value.size,
                name: value.name,
                type: value.type,
              };
              this.$refs.refLoadingPhotos.manuallyAddFile(file, value.url);
            });
          }

          if (this.form.loaded_photos.length > 0) {
            this.form.loaded_photos.map((value) => {
              let file = {
                size: value.size,
                name: value.name,
                type: value.type,
              };
              this.$refs.refLoadedPhotos.manuallyAddFile(file, value.url);
            });
          }

          if (this.form.export_invoice_photo.length > 0) {
            this.form.export_invoice_photo.map((value) => {
              let file = { size: value.size, url: value.url, type: value.type };
              var ext = file.url.split(".").pop();
              let url = file.url;
              if (ext == "pdf") {
                url = Api.defaults.imageURL + "/images/pdf_thumbnail.jpg";
              }

              this.$refs.vueDropzoneExprotInvoice.manuallyAddFile(
                file,
                url,
                null,
                null,
                {
                  dontSubstractMaxFiles: false,
                  addToFiles: true,
                }
              );
            });
          }

          this.dialog = true;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }
          if (err.response && err.response.status !== 401) {
            this.$toastr.e("Failed to load data! " + err);
          }
        });
    },
    makeApi(id) {
      this.dropzoneOptionsContinerImage.url = `${Api.defaults.baseURL}/exports/${id}/photos-upload?type=1`;
      this.dropzoneOptionsExprotInvoice.url = `${Api.defaults.baseURL}/exports/${id}/documents-upload`;
      this.dropzoneOptionsEmptyContainer.url = `${Api.defaults.baseURL}/exports/${id}/photos-upload?type=2`;
      this.dropzoneOptionsLoadingPhotos.url = `${Api.defaults.baseURL}/exports/${id}/photos-upload?type=3`;
      this.dropzoneOptionsLoadedPhotos.url = `${Api.defaults.baseURL}/exports/${id}/photos-upload?type=4`;
    },
    cancel() {
      this.dataId = false;
      this.editing = false;
      this.dialog = false;
      this.$emit("cancelForm");
    },

    removeFileExprotInvoice(file) {
      this.form.export_invoice = "";
      // this.form.export_invoice_photo = [];
      let index = this.form.export_invoice_photo
        ? this.form.export_invoice_photo.findIndex((x) => x.name === file.name)
        : -1;
      this.form.export_invoice_photo.splice(index, 1);
    },
    removeFileContinerImage(file) {
      // this.form.container_images = [];
      let index = this.form.container_images
        ? this.form.container_images.findIndex((x) => x.name === file.name)
        : -1;
      this.form.container_images.splice(index, 1);
    },
    removeFileEmptyContainer(file) {
      // this.form.empty_container_photos = [];
      let index = this.form.empty_container_photos
        ? this.form.empty_container_photos.findIndex(
            (x) => x.name === file.name
          )
        : -1;
      this.form.empty_container_photos.splice(index, 1);
    },
    removeFileLoadingPhotos(file) {
      // this.form.loading_photos = [];
      let index = this.form.loading_photos
        ? this.form.loading_photos.findIndex((x) => x.name === file.name)
        : -1;
      this.form.loading_photos.splice(index, 1);
    },
    removeFileLoadedPhotos(file) {
      // this.form.loaded_photos = [];

      let index = this.form.loaded_photos
        ? this.form.loaded_photos.findIndex((x) => x.name === file.name)
        : -1;
      this.form.loaded_photos.splice(index, 1);
    },

    // Remove All Files Photos Functions
    removeAllFileExprotInvoice() {
      this.form.export_invoice = "";
      this.form.export_invoice_photo = [];
      if (this.$refs.vueDropzoneExprotInvoice) {
        this.$refs.vueDropzoneExprotInvoice.removeAllFiles();
      }
    },
    removeAllFileContinerImage() {
      this.form.container_images = [];
      if (this.$refs.vueDropzoneContinerImage) {
        this.$refs.vueDropzoneContinerImage.removeAllFiles();
      }
    },
    removeAllFileEmptyContainer() {
      this.form.empty_container_photos = [];
      if (this.$refs.vueDropzoneEmptyContainer) {
        this.$refs.vueDropzoneEmptyContainer.removeAllFiles();
      }
    },
    removeAllFileLoadingPhotos() {
      this.form.loading_photos = [];
      if (this.$refs.refLoadingPhotos) {
        this.$refs.refLoadingPhotos.removeAllFiles();
      }
    },
    removeAllFileLoadedPhotos() {
      this.form.loaded_photos = [];
      if (this.$refs.refLoadedPhotos) {
        this.$refs.refLoadedPhotos.removeAllFiles();
      }
    },

    sendingEventEmptyContainer(file, xhr, formData) {
      this.loading = true;
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
    },

    sendingEventContinerImage(file, xhr, formData) {
      this.loading = true;
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
    },
    sendingEventExprotInvoice(file, xhr, formData) {
      this.loading = true;
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
    },

    sendingEventLoadingPhotos(file, xhr, formData) {
      this.loading = true;
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
    },
    sendingEventLoadedPhotos(file, xhr, formData) {
      this.loading = true;
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
    },

    storeSuccessExprotInvoice(file, response) {
      this.loading = false;
      this.form.fileurl.export_invoice_photo.push(response.data);
    },
    storeSuccessContinerImage(file, response) {
      this.loading = false;
      this.form.fileurl.container_images.push(response.data);
    },
    storeSuccessEmptyContainer(file, response) {
      this.loading = false;
      this.form.fileurl.empty_container_photos.push(response.data);
    },

    storeSuccessLoadingPhotos(file, response) {
      this.loading = false;
      this.form.fileurl.loading_photos.push(response.data);
    },

    storeSuccessLoadedPhotos(file, response) {
      this.loading = false;
      this.form.fileurl.loaded_photos.push(response.data);
    },

    storeErrorExprotInvoice(file, message, xhr) {
      this.dialog = true;
      if (xhr.status === 422) {
        // this.errors = message.errors;
      } else {
        this.dialog = false;
        this.loading = false;
      }

      this.$toastr.e("Failed to create data! " + message.message);
    },
    storeErrorContinerImage(file, message, xhr) {
      this.dialog = true;
      if (xhr.status === 422) {
        // this.errors = message.errors;
      } else {
        this.dialog = false;
        this.loading = false;
      }

      this.$toastr.e("Failed to create data! " + message.message);
    },
    storeErrorEmptyContainer(file, message, xhr) {
      this.dialog = true;
      if (xhr.status === 422) {
        // this.errors = message.errors;
      } else {
        this.dialog = false;
        this.loading = false;
      }

      this.$toastr.e("Failed to create data! " + message.message);
    },

    storeErrorLoadingPhotos(file, message, xhr) {
      this.dialog = true;
      if (xhr.status === 422) {
        // this.errors = message.errors;
      } else {
        this.dialog = false;
        this.loading = false;
      }

      this.$toastr.e("Failed to create data! " + message.message);
    },

    storeErrorLoadedPhotos(file, message, xhr) {
      this.dialog = true;
      if (xhr.status === 422) {
        // this.errors = message.errors;
      } else {
        this.dialog = false;
        this.loading = false;
      }

      this.$toastr.e("Failed to create data! " + message.message);
    },

    removeAllFilesExprotInvoice(dropzoneOnly = false) {
      this.removeFilesFromDropzoneOnlyExprotInvoice = dropzoneOnly;
      this.form.export_invoice_photo = [];
      if (this.$refs.vueDropzoneExprotInvoice) {
        this.$refs.vueDropzoneExprotInvoice.removeAllFiles();
      }
    },

    cusotmarAutoCompleteHandelar(e) {
      this.customarIdLoading = true;
      Api.get(`/customers-item?customer_name=${e}`)
        .then((res) => {
          this.items = res.data.data;
          this.customer = res.data.data;
          this.exporters = res.data.data;
          this.customarIdLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }
          if (err.response.status !== 401) {
            this.customarIdLoading = false;
            this.$toastr.e("Failed to create data! " + err);
          }
        });
    },

    shipperAutoCompleteHandelar(e) {
      this.customarIdLoading = true;
      Api.get(`/customers-item?customer_name=${e}`)
        .then((res) => {
          this.shippers = res.data.data;
          this.customarIdLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }
          if (err.response.status !== 401) {
            this.customarIdLoading = false;
            this.$toastr.e("Failed to create data! " + err);
          }
        });
    },
    vinSearchAutoCompleteHandelar(vin) {
      this.containersLoading = true;
      Api.get(`/vehicle-search?vin=${vin}`)
        .then((res) => {
          this.vinSearch = res.data.data;
          this.containersLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }
          if (err.response.status !== 401) {
            this.containersLoading = false;
            this.$toastr.e("Failed to create data! " + err);
          }
        });
    },
    vinSearchTableData() {
      if (this.form.vehicle_ids.indexOf(this.vinSearchId) !== -1) {
        this.$toastr.e("Vehicle already exists! ");
        return;
      }
      Api.get(`vehicles/${this.vinSearchId}`)
        .then((res) => {
          if (
            this.vehicles &&
            this.vehicles.length &&
            this.vehicles[0].location_id !== res.data.data.location_id
          ) {
            this.$toastr.e(
              "Multiple location vehicle not allowed in one container!"
            );
            return;
          }
          this.vehicles.push(res.data.data);
          this.form.vehicle_ids.push(res.data.data.id);
          this.vinSearchId = "";
        })
        .catch((err) => {
          if (err.response.status && err.response.status !== 401) {
            this.$toastr.e("Failed to create data! " + err);
          }
        });
    },
    consigneeDataHandle() {
      Api.get(`consignee-search?customer_user_id=${this.form.customer_user_id}`)
        .then((res) => {
          this.consignee_detail = res.data;
          this.consigneeData = res.data;
          this.notify_party_detail = res.data;
          this.menifest_consignee_detail = res.data;
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to create data! " + err);
          }
        });
    },
    customarInfoHandel() {
      for (const i of this.items) {
        if (i.user_id === this.form.customer_user_id) {
          this.customarCompanyName = i.company_name;
          this.legacyCustomerId = i.legacy_customer_id;
        }
      }
      this.consigneeDataHandle();
    },

    fetchEntriesDebounced(val) {
      // cancel pending call
      clearTimeout(this._timerId);

      // delay new call 500ms
      this._timerId = setTimeout(() => {
        this.cusotmarAutoCompleteHandelar(val);
      }, 700);
    },

    fetchShipperEntriesDebounced(val) {
      // cancel pending call
      clearTimeout(this._timerId);

      // delay new call 500ms
      this._timerId = setTimeout(() => {
        this.shipperAutoCompleteHandelar(val);
      }, 700);
    },

    getShipperItems() {
      Api.get(`customers-item?user_id=7000948`).then((res) => {
        this.shippers = res.data.data;
      });
    },
  },

  created() {
    this.emptyForm();
    this.getShipperItems();
  },

  watch: {
    id: function (newVal, oldVal) {
      if (newVal || oldVal) {
        this.dataId = newVal;
        this.editing = true;
      } else {
        this.dataId = false;
        this.editing = false;
      }
    },
    dialog(newVal) {
      if (this.detailspage && newVal) {
        this.editing = true;
      }
    },
    show: function (newVal) {
      this.dialog = newVal;
    },
    // "form.customer_user_id": function () {
    //   this.customarInfoHandel();
    // },
    searchVinId: function (newVal) {
      if (newVal) {
        this.vinSearchAutoCompleteHandelar(newVal);
      }
    },
    customarUserId: function (newVal) {
      // if (newVal) {
      //   this.cusotmarAutoCompleteHandelar(newVal);
      // }

      this.customarInfoHandel();

      if (!newVal) {
        return;
      }

      this.fetchEntriesDebounced(newVal);
    },
    shipperId: function (newVal) {
      if (!newVal) {
        return;
      }

      this.fetchShipperEntriesDebounced(newVal);
    },
  },
};
</script>
<style scoped>
.export-modal .row .col-12 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.export-modal__title {
  padding-bottom: 20px;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
}

.export-modal hr {
  background: #ebebeb;
}

.pos {
  position: absolute !important;
}
</style>

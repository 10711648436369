<template>
  <div class="home">
    <div
      v-if="roleId == 3 || roleId == 8"
      style="height: 50vh"
      class="d-flex align-center justify-center"
    >
      <h1 class="display-2 text-center">
        You are not allowed to view this page
      </h1>
    </div>
    <v-container fluid class="bg-white mt-65" v-else>
      <v-row>
        <v-col
          cols="12"
          md="6"
          class="pa-8"
          v-for="(status, i) in statuses"
          :key="i"
          style="border-bottom: 3px solid #eeee"
        >
          <DailyReport
            :status="status.title"
            :locations="status.locations"
          ></DailyReport>
        </v-col>
      </v-row>
      <div class="divider"></div>
      <v-row class="ml-2 mt-2">
        <h1 class="mb-sm-5">Container Report</h1>
        <v-btn
          :loading="filtering || downloading"
          color="primary"
          @click="exportData"
          class="ml-sm-auto mr-7 mt-sm-4 mt-3 mb-5"
        >
          Export
        </v-btn>
      </v-row>
      <div>
        <v-row>
          <v-col cols="12" md="2">
            <v-select
              dense
              outlined
              :items="[20, 50, 75, 100]"
              v-model="pagination.per_page"
              :label="`Showing ${pagination.per_page} Items`"
              @change="getData(1)"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              @keypress.enter="search"
              dense
              outlined
              v-model="filters.report_global_search"
              @keyup.enter.native="search"
              label="CUSTOMER ID, CUSTOMER NAME, USER EMAIL, COMPANY NAME, CITY, STATE, COUNTRY, TAX"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-select
              dense
              outlined
              :items="reportTypes"
              item-text="value"
              item-value="key"
              v-model="filters.report_type"
              label="Report Type"
            ></v-select>
          </v-col>
          <v-col cols="12" md="2">
            <!--<v-autocomplete-->
            <!--dense-->
            <!--outlined-->
            <!--v-model="filters.customer_user_id"-->
            <!--:items="customersItem"-->
            <!--item-text="customer_name"-->
            <!--item-value="user_id"-->
            <!--:search-input.sync="customarUserId"-->
            <!--hide-no-data-->
            <!--label="CUSTOMER"-->
            <!--autocomplete="none"-->
            <!--:loading="loadingCustomar"-->
            <!--:error-messages="errors.customer_user_id"-->
            <!--&gt;</v-autocomplete>-->

            <v-autocomplete
              dense
              outlined
              v-model="filters.customer_user_id"
              :items="customersItem"
              item-text="customer_name"
              item-value="user_id"
              label="CUSTOMER"
              :search-input.sync="customarUserId"
              :loading="loadingCustomar"
              :error-messages="errors.customer_user_id"
              no-filter
            >
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="
                        data.item.customer_name +
                        ' (' +
                        data.item.legacy_customer_id +
                        ')'
                      "
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-html="data.item.company_name"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-autocomplete
              dense
              outlined
              v-model="filters.container_number"
              :items="containers"
              item-text="container_number"
              item-value="container_number"
              :loading="containersLoading"
              :search-input.sync="getContainers"
              placeholder="Start typing to Search"
              label="Container"
              autocomplete="none"
              :error-messages="errors.container_number"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <v-menu
              ref="datePicker1"
              v-model="datePicker1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  v-model="filters.start_date"
                  label="Date from"
                  prepend-icon="mdi-calendar"
                  readonly
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.start_date"
                @input="datePicker1 = false"
                no-title
                scrollable
                style="min-height: 350px"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" md="3">
            <v-menu
              ref="datePicker2"
              v-model="datePicker2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  v-model="filters.end_date"
                  label="Date to"
                  prepend-icon="mdi-calendar"
                  readonly
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                dense
                v-model="filters.end_date"
                no-title
                scrollable
                @input="datePicker2 = false"
                style="height: 350px"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="3">
            <v-btn :loading="filtering" color="primary" @click="search">
              Search
            </v-btn>
          </v-col>
          <v-col cols="12" md="3" class="text-left">
            <h5>
              Showing : {{ this.showingFrom ? this.showingFrom : "0" }} -
              {{ this.showingTo ? this.showingTo : "0" }} of
              {{ this.count ? this.count : "0" }}
            </h5>
          </v-col>
        </v-row>
      </div>
      <v-simple-table class="mt-10" v-if="!loading">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">LOADING DATE</th>
              <th class="text-left">EXPORT DATE</th>
              <th class="text-left">ETA</th>
              <th class="text-left">BOOKING NO</th>
              <th class="text-left">CONTAINER NO</th>
              <th class="text-left">CUSTOMER</th>
              <th class="text-left">TERMINAL</th>
              <th class="text-left">VESSEL</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in report" :key="index">
              <td>{{ item.loading_date }}</td>
              <td>{{ item.export_date }}</td>
              <td>{{ item.eta }}</td>
              <td>{{ item.booking_number }}</td>
              <td>{{ item.container_number }}</td>
              <td>{{ item.customer_name }}</td>
              <td>{{ item.terminal }}</td>
              <td>{{ item.vessel }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <vue-content-loading :width="300" :height="100" v-else>
        <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
      </vue-content-loading>

      <v-row justify="center" style="margin: 0">
        <v-col cols="8">
          <v-container class="max-width">
            <v-pagination
              v-model="pagination.current"
              :length="pagination.total"
              class="my-4"
              :total-visible="7"
              circle
              @input="getData(pagination.current)"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Api from "@/services/Api";
import { VueContentLoading } from "vue-content-loading";
import DailyReport from "./components/DailyReport";
import downloadFile from "@/helpers/downloadFile";

export default {
  components: {
    VueContentLoading,
    DailyReport,
  },
  data() {
    return {
      roleId: JSON.parse(localStorage.getItem("user")).role,
      loadingCustomar: false,
      customarUserId: "",
      customersItem: [],
      getContainers: null,
      datePicker1: null,
      datePicker2: null,
      menu2: null,
      customers: null,
      containers: [],
      statuses: [],
      report: {},
      reportTypes: [
        { key: "container_shipped", value: "CONTAINER SHIPPED" },
        { key: "container_loaded", value: "CONTAINER LOADED" },
        { key: "container_arrived", value: "CONTAINER ARRIVED" },
        { key: "manifested_container", value: "MANIFEST CONTAINER" },
      ],
      pagination: {
        per_page: 20,
        current: 1,
        total: 0,
      },
      filters: {
        report_global_search: "",
        customer_user_id: "",
        container_number: "",
        report_type: "container_shipped",
        start_date: new Date(
          Date.now() -
            new Date().getTimezoneOffset() * 60000 -
            30 * 24 * 3600 * 1000
        )
          .toISOString()
          .substr(0, 10),
        end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      errors: {},
      loading: false,
      containersLoading: false,
      filtering: false,
      showingFrom: 0,
      showingTo: 0,
      count: 0,
      userId: JSON.parse(localStorage.getItem("user")).id,
      downloading : false,
    };
  },
  methods: {
    getData(page) {
      this.report = {};
      this.statuses = [];
      this.loading = true;
      if (!page) {
        page = this.pagination.current;
      }

      this.filters.page = page;
      this.filters.limit = this.pagination.per_page;
      var filter = new URLSearchParams(this.filters).toString();

      let finalUrl = this.$route.path + `?` + filter;
      window.history.pushState({}, "", finalUrl);

      Api.get(`/reports/container-report?${filter}`)
        .then((res) => {
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
          this.report = res.data.containers.data;
          this.statuses = res.data.location_reports;
          this.pagination.current = res.data.containers.current_page;
          this.pagination.total = res.data.containers.last_page;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },

    getDataWithFilter(filter) {
      Api.get(`/reports/container-report?${filter}`)
        .then((res) => {
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
          this.report = res.data.containers.data;
          this.statuses = res.data.location_reports;
          this.pagination.current = res.data.containers.current_page;
          this.pagination.total = res.data.containers.last_page;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },

    search() {
      this.getData(1);
    },
    getCustomers() {
      this.loading = true;
      Api.get(`/customers`)
        .then((res) => {
          this.customers = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });
    },
    loadingManage (value) {
      this.downloading = value;
    },
    exportData() {
      this.loadingManage(true);
      var filter = new URLSearchParams(this.filters).toString();
      // window.location =
      //   Api.defaults.baseURL +
      //   `/reports/container-report/export?${filter}&auth_user_id=${this.userId}`;

      const url = Api.defaults.baseURL +`/reports/container-report/export?${filter}&auth_user_id=${this.userId}`;
      downloadFile(url,
          'excel_export',
          '.xls',
          this.loadingManage
      );
    },
    filtersUrl() {
      var str = "";
      for (var key in this.filters) {
        if (str != "") {
          str += "&";
        }
        str += key + "=" + encodeURIComponent(this.filters[key]);
      }
      return str;
    },
  },
  mounted() {
    // this.getData();
    // this.getCustomers();

    if (Object.keys(this.$route.query).length) {
      this.filters = this.$route.query;
      if (this.$route.query.customer_user_id) {
        this.customarUserId = this.$route.query.customer_user_id;
      }

      this.getDataWithFilter(this.filtersUrl());
    } else {
      this.getData(1);
    }

    this.getCustomers();
  },
  watch: {
    getContainers(query) {
      this.containers = [];
      this.containersLoading = true;
      Api.get(`/containers-search?q=${query}`)
        .then((res) => {
          this.containers = res.data.data;
          this.containersLoading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.containersLoading = false;
          }
        });
    },
    customarUserId: function (newVal) {
      if (newVal) {
        this.loadingCustomar = true;
        Api.get(`/customers-item?customer_name=${newVal}`)
          .then((res) => {
            this.customersItem = res.data.data;
            this.loadingCustomar = false;
          })
          .catch((err) => {
            if (err.response.status !== 401) {
              this.$toastr.e("Failed to load data!" + err);
              this.loadingCustomar = false;
            }
          });
      }
    },
  },
};
</script>
<style>
.home {
  background-color: #f8f8fb;
}
.divider {
  height: 10px;
  width: 103%;
  margin-left: -12px;
  background-color: #f8f8fb;
}
</style>
